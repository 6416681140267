import moment from "moment-timezone";
import countries from "./countries.json";
import states from "./state.json";
import cities from "./cities.json";
import counties from "./county.json";

export const rubricEvaluation = [
  {
    category: "Ideas and Analysis",
    fieldName: "ideasAndAnalysisScore",
    score: [
      {
        number: 12,
        description:
          "The writer generates an argument that  critically engages with multiple perspectives on the given issue. The argument’s thesis reflects nuance and precision in thought and purpose. The argument establishes and employs an insightful context for analysis of the issue and its perspectives. The analysis examines implications, complexities and tensions, and/or underlying values and assumptions.",
        status: true,
      },
      {
        number: 10,
        description:
          "The writer generates an argument that productively engages with multiple perspectives on the given issue. The argument’s thesis reflects precision in thought and purpose. The argument establishes and employs a thoughtful context for analysis of the issue and its perspectives. The analysis addresses implications, complexities and tensions, and/or underlying values and assumptions. ",
        status: false,
      },
      {
        number: 8,
        description:
          "The writer generates an argument that engages with multiple perspectives on the given issue. The argument’s thesis reflects clarity in thought and purpose. The argument establishes and employs a relevant context for analysis of the issue and its perspectives. The analysis recognizes implications, complexities and tensions, and/or underlying values and assumptions.",
        status: false,
      },
      {
        number: 6,
        description:
          "The writer generates an argument that responds to multiple perspectives on the given issue. The argument’s thesis reflects some clarity in thought and purpose. The argument establishes a limited or tangential context for analysis of the issue and its perspectives. Analysis is simplistic or somewhat unclear.",
        status: false,
      },
      {
        number: 4,
        description:
          "The writer generates an argument that weakly responds to multiple perspectives on the given issue. The argument’s thesis, if evident, reflects little clarity in thought and purpose. Attempts at analysis are incomplete, largely irrelevant, or consist primarily of restatement of the issue and its perspectives.",
        status: false,
      },
      {
        number: 2,
        description:
          "The writer fails to generate an argument that responds intelligibly to the task. The writer’s intentions are difficult to discern. Attempts at analysis are unclear or irrelevant.",
        status: false,
      },
    ],
  },
  {
    category: "Development and Support",
    fieldName: "developmentAndSupportScore",
    score: [
      {
        number: 12,
        description:
          "Development of ideas and support for claims deepen insight and broaden context.  An integrated line of skillful reasoning and illustration effectively conveys the significance of the argument. Qualifications and complications enrich and bolster ideas and analysis.",
        status: false,
      },
      {
        number: 10,
        description:
          "Development of ideas and support for claims deepen understanding. A mostly integrated line of purposeful reasoning and illustration capably conveys the significance of the argument. Qualifications and complications enrich ideas and analysis.",
        status: false,
      },
      {
        number: 8,
        description:
          "Development of ideas and support for claims clarify meaning and purpose. Lines of clear reasoning and illustration adequately convey the significance of the argument.  Qualifications and complications extend ideas and analysis.",
        status: true,
      },
      {
        number: 6,
        description:
          "Development of ideas and support for claims are mostly relevant but are overly general or simplistic. Reasoning and illustration largely clarify the argument but may be somewhat repetitious or imprecise.",
        status: false,
      },
      {
        number: 4,
        description:
          "Development of ideas and support for claims are weak, confused, or disjointed. Reasoning and illustration are inadequate, illogical, or circular, and fail to fully clarify the argument.",
        status: false,
      },
      {
        number: 2,
        description:
          "Ideas lack development, and claims lack support. Reasoning and illustration are unclear, incoherent, or largely absent.",
        status: false,
      },
    ],
  },
  {
    category: "Organization",
    fieldName: "organizationScore",
    score: [
      {
        number: 12,
        description:
          "The response exhibits a skillful organizational strategy. The response is unified by a controlling idea or purpose, and a logical progression of ideas increases the effectiveness of the writer’s argument. Transitions between and within paragraphs strengthen the relationships among ideas.",
        status: false,
      },
      {
        number: 10,
        description:
          "The response exhibits a productive organizational strategy. The response is mostly unified by a controlling idea or purpose, and a logical sequencing of ideas contributes to the effectiveness of the argument. Transitions between and within paragraphs consistently clarify the relationships among ideas.",
        status: false,
      },
      {
        number: 8,
        description:
          "The response exhibits a clear organizational strategy. The overall shape of the response reflects an emergent controlling idea or purpose. Ideas are logically grouped and sequenced. Transitions between and within paragraphs clarify the relationships among ideas.",
        status: true,
      },
      {
        number: 6,
        description:
          "The response exhibits a basic organizational structure. The response largely coheres, with most ideas logically grouped. Transitions between and within paragraphs sometimes clarify the relationships among ideas.",
        status: false,
      },
      {
        number: 4,
        description:
          "The response exhibits a rudimentary organizational structure. Grouping of ideas is inconsistent and often unclear. Transitions between and within paragraphs are misleading or poorly formed.  ",
        status: false,
      },
      {
        number: 2,
        description:
          "The response does  not exhibit an organizational structure.  There is little grouping of ideas. When present, transitional devices fail to connect ideas.",
        status: false,
      },
    ],
  },
  {
    category: "Language Use",
    fieldName: "languageUseScore",
    score: [
      {
        number: 12,
        description:
          "The use of language enhances the argument. Word choice is skillful and precise. Sentence structures are consistently varied and clear. Stylistic and register choices, including voice and tone, are strategic and effective.  While a few minor errors in grammar, usage, and mechanics may be present, they do not impede understanding.",
        status: false,
      },
      {
        number: 10,
        description:
          "The use of language works in service of the argument. Word choice is precise. Sentence structures are clear and varied often. Stylistic and register choices, including voice and tone, are purposeful and productive. While minor errors in grammar, usage, and mechanics may be present, they do not impede understanding.",
        status: false,
      },
      {
        number: 8,
        description:
          "The use of language conveys the argument with clarity. Word choice is adequate and sometimes precise. Sentence structures are clear and demonstrate some variety. Stylistic and register choices, including voice and tone are appropriate for the rhetorical purpose. While errors in grammar, usage, and mechanics are present, they rarely impede understanding.",
        status: true,
      },
      {
        number: 6,
        description:
          "The use of language is basic and only somewhat clear. Word choice is general and occasionally imprecise. Sentence structures are usually clear but show little variety. Stylistic and register choices, including voice and tone, are not always appropriate for the rhetorical purpose. Distracting errors in grammar, usage, and mechanics may be present, but they generally do not impede understanding.",
        status: false,
      },
      {
        number: 4,
        description:
          "The use of language is inconsistent and often unclear. Word choice is rudimentary and frequently imprecise. Sentence structures are sometimes unclear. Stylistic and register choices, including voice and tone, are inconsistent and are not always appropriate for the rhetorical purpose. Distracting errors in grammar, usage, and mechanics are present, and they sometimes impede understanding.",
        status: false,
      },
      {
        number: 2,
        description:
          "The use of language fails to demonstrate skill in responding to the task. Word choice is imprecise and often difficult to comprehend. Sentence structures are often unclear. Stylistic and register choices are difficult to identify. Errors in grammar, usage, and mechanics are pervasive and often impede understanding.",
        status: false,
      },
    ],
  },
];

const userRoles = {
  SUPER_ADMIN: "UpScoreSuperAdmin",
  IND_STUDENT: "IndividualStudent",

  SCHOOl: "School",
  PROGRAM_SITES: "ProgramSite",

  SCHOOL_SUB_ADMIN: "SchoolSubAdmin",
  PROGRAM_SITES_SUB_ADMIN: "ProgramSiteSubAdmin",

  SCHOOL_TEACHER: "SchoolTeacher",
  PROGRAM_SITES_TEACHER: "ProgramSiteTeacher",

  PROGRAM_SUPER_ADMIN: "ProgramSuperAdmin",
  DISTRICT_SUPER_ADMIN: "DistrictSuperAdmin",
};

const userRolesForHeader = {
  UpScoreSuperAdmin: "UpScore Super Admin",
  IndividualStudent: "Individual Student",
  School: "School",
  ProgramSite: "Program Site",
  ProgramSiteSubAdmin: "Program Site Sub-admin",
  SchoolTeacher: "School Teacher",
  ProgramSiteTeacher: "Program Site Teacher",
  ProgramSuperAdmin: "Program Super-admin",
  DistrictSuperAdmin: "District Super-admin",
};

/*
------------------
  Get Time Stamp
------------------
*/
// const getDateTimeCST = (data) => {
//   // return moment(data).format("lll");
//   //moment.tz.guess()
//   return moment(data).tz(moment.tz.guess() || 'America/Mexico_City').format("MMM DD, YYYY | h:mm A");
// };

const getExpyDate = (data) => {
  // return moment(data).format("lll");
  return moment(data)
    .tz(moment.tz.guess() || "America/Mexico_City")
    .format("MMM DD, YYYY");
};

const formatDateClientTZ = (data) => {
  return moment(data).tz(moment.tz.guess()).format("MMM DD, YYYY | h:mm A");
};

const getDateTime = (data) => {
  return moment(data)
    .tz(moment.tz.guess() || "America/Mexico_City")
    .format("MMM DD, YYYY | h:mm A");
};

// format date without timezone converting
const getFormatDate = (data) => {
  if (data) return moment(data.split("T")[0]).format("MMM DD, YYYY | h:mm A");
  else return "";
};

const isSeatExpire = (date) => {
  return moment().isAfter(date); // if current date greater than isAfter(date) then return true.
};

const paginationLimit = 10; // for pagination limit
/*
------------------
  Get Countries
------------------
*/
const countriesData = () => {
  let countriesOpt = [];
  countries.forEach(({ id, name }) => {
    countriesOpt.push({
      label: name,
      value: id,
    });
  });
  return countriesOpt;
};

/*
------------------
  Get States
------------------
*/
const statesData = (countryID) => {
  let statesOpt = [];
  states.forEach(({ id, name, countryid }) => {
    if (parseInt(countryid) === parseInt(countryID)) {
      statesOpt.push({
        label: name,
        value: parseInt(id),
      });
    }
  });
  return statesOpt;
};

/*
------------------
  Get Counties
------------------
*/
const countiesData = (stateID) => {
  let countiesOpt = [];
  counties.forEach(({ id, name, stateid }) => {
    if (parseInt(stateid) === parseInt(stateID)) {
      countiesOpt.push({
        label: name,
        value: parseInt(id),
      });
    }
  });
  return countiesOpt;
};

/*
------------------
  Get Cities
------------------
*/
const citiesData = (stateID) => {
  let citiesOpt = [];
  cities.forEach(({ id, name, state_id }) => {
    if (parseInt(state_id) === parseInt(stateID)) {
      citiesOpt.push({
        label: name,
        value: parseInt(id),
      });
    }
  });
  return citiesOpt;
};

/*
------------------------------------
  Set Select Drop down Label Values
------------------------------------
*/
const setLabelValue = (value, fieldOptions) => {
  if (value !== null) {
    return fieldOptions.filter(
      (item) => parseInt(item.value) === parseInt(value)
    )[0];
  } else {
    return null;
  }
};

const setLabelValue2 = (value, fieldOptions) => {
  if (value !== null) {
    console.log("value", value);
    console.log("fieldOptions", fieldOptions);
    return fieldOptions.filter((item) => item.value === value)[0];
  } else {
    return null;
  }
};

/*
------------------------------------
  Set Select Drop down Label Values
------------------------------------
*/
const districtDropDownSet = (data) => {
  let districtData = [];
  data.forEach(({ id, districtName }) => {
    districtData.push({
      label: districtName,
      value: id,
    });
  });
  return districtData;
};

/*
-----------------------
  Data Status Options
-----------------------
*/
const subadminTitleOptions = [
  { value: "Principal", label: "Principal" },
  { value: "AssistantPrincipal", label: "Assistant Principal" },
  { value: "Administrator", label: "Administrator" },
  { value: "Counselor", label: "Counselor" },
  { value: "Secretary", label: "Secretary" },
];

const programSiteSubadminJobTitles = [
  { value: "Administrator", label: "Administrator" },
  { value: "Counselor", label: "Counselor" },
  { value: "Secretary", label: "Secretary" },
];

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];
const testStatusOptions = [
  { value: "Completed", label: "Complete" },
  { value: "Missed", label: "Incomplete" },
];

const testNameOptions = [
  { value: "60e06ad678a69b9d7105fa6b", label: "Test - 1" },
  { value: "60e06ad678a69b9d7105fa6c", label: "Test - 2" },
  { value: "60e06ad678a69b9d7105fa6d", label: "Test - 3" },
  { value: "60e06ad678a69b9d7105fa6e", label: "Test - 4" },
  { value: "60e06ad678a69b9d7105fa6f", label: "Test - 5" },
];

const essayOptions = [
  { value: "Graded", label: "Graded" },
  { value: "Ungraded", label: "Ungraded" },
];

const classAssignedOptions = [
  { value: "Assigned", label: "Assigned" },
  { value: "Unassigned", label: "Unassigned" },
];

const paymentStatusOptions = [
  { value: "Success", label: "Success" },
  { value: "Failed", label: "Failed" },
  { value: "Refund", label: "Refunded" },
];

const seatStatusOptions = [
  { value: "Assigned", label: "Assigned" },
  { value: "Unassigned", label: "Unassigned" },
  { value: "Expired", label: "Expired" },
  { value: "Max Time Reached", label: "Max Assignments Reached" },
];

const seatNumberOfTimesOptions = [
  { value: 1, label: "Once" },
  { value: 2, label: "Twice" },
];

const noTimesUsedOptions = [
  { value: "Once", label: "Once" },
  { value: "Twice", label: "Twice" },
];

const subscriptionOptions = [
  { value: "Free", label: "Free" },
  { value: "Paid", label: "Paid" },
];

const timeOptions = [
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Year", label: "Year" },
  { value: "Specific Date Range", label: "Specific Date Range" },
];
const yesOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const UpscoreTestOptions = [
  { value: "practice-test-1-diagnostic-test", label: "Practice Test 1" },
  { value: "practice-test-2", label: "Practice Test 2" },
  { value: "practice-test-3", label: "Practice Test 3" },
  { value: "practice-test-4", label: "Practice Test 4" },
  { value: "practice-test-5", label: "Practice Test 5" },
];

const TestOptions = [
  {
    value: "after",
    label: "Official ACT Practice Test Score After UpScore Test Prep",
  },
  { value: "averagePracticeTest", label: "Avg. Practice Test Score" },
  { value: "practiceTest1", label: "Practice Test 1" },
  { value: "practiceTest2", label: "Practice Test 2" },
  { value: "practiceTest3", label: "Practice Test 3" },
  { value: "practiceTest4", label: "Practice Test 4" },
  { value: "practiceTest5", label: "Practice Test 5" },
];
const passwordSpaceRegex = /^\S*$/;
const TestOptionsB = [
  {
    value: "before",
    label: "Official ACT Practice Test Score Before UpScore Test Prep",
  },
  { value: "averagePracticeTest", label: "Avg. Practice Test Score" },
  { value: "practiceTest1", label: "Practice Test 1" },
  { value: "practiceTest2", label: "Practice Test 2" },
  { value: "practiceTest3", label: "Practice Test 3" },
  { value: "practiceTest4", label: "Practice Test 4" },
  { value: "practiceTest5", label: "Practice Test 5" },
];

const CourseOptions = [
  { value: "fullCourse", label: "Full Course" },
  { value: "learningPlan", label: "Learning Plan" },
  // { value: "assignments", label: "Assignments" },
];

const fullCourseOptions = [{ value: "fullCourse", label: "Full Course" }];

const selectOptions = [
  { label: "Single", value: "Single" },
  { label: "Multiple", value: "Multiple" },
];

const ActScoreOptions = [
  { value: "Composite", label: "Composite" },
  { value: "English", label: "English" },
  { value: "Math", label: "Math" },
  { value: "Reading", label: "Reading" },
  { value: "Science", label: "Science" },
  { value: "Writing", label: "Writing" },
];

const QuizScoreOptions = [
  { value: "allSubjects", label: "All Subjects" },
  { value: "english", label: "English" },
  { value: "math", label: "Math" },
  { value: "reading", label: "Reading" },
  { value: "science", label: "Science" },
  // { value: "Writing", label: "Writing" },
];

const alphaNumeric = /^[A-Za-z0-9 ]+$/;

const ethnicityOptions = [
  {
    value: "American Indian or Alaska Native",
    label: "American Indian or Alaska Native",
  },
  { value: "Asian", label: "Asian" },
  { value: "Black or African American", label: "Black or African American" },
  { value: "Hispanic or Latino", label: "Hispanic or Latino" },
  {
    value: "Native Hawaiian or Pacific Islander",
    label: "Native Hawaiian or Pacific Islander",
  },
  { value: "White (Non-Hispanic)", label: "White (Non-Hispanic)" },
  { value: "Two or More Races", label: "Two or More Races" },
];

const gradeOptions = [
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "College", label: "College" },
  { value: "Not enrolled in school", label: "Not enrolled in school" },
];

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const sortOptions = [
  { value: "Ascending", label: "Ascending" },
  { value: "Descending", label: "Descending" },
];

const suffixOptions = [
  { value: "Jr", label: "Jr" },
  { value: "Sr", label: "Sr" },
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
  { value: "IV", label: "IV" },
  { value: "V", label: "V" },
  { value: "VI", label: "VI" },
];

const subjectOptions = [
  { value: "English", label: "English" },
  { value: "Math", label: "Math" },
  { value: "Science", label: "Science" },
  { value: "Reading", label: "Reading" },
  { value: "Writing", label: "Writing" },
  { value: "All Subjects", label: "All Subjects" },
];

const departmentOptions = [
  { value: "Business", label: "Business" },
  { value: "ComputerScience", label: "Computer Science" },
  { value: "English", label: "English" },
  { value: "ConsumerScience", label: "Family & Consumer Science" },
  { value: "ForeignLanguage", label: "Foreign Language" },
  { value: "Math", label: "Math" },
  { value: "PerformingArts", label: "Arts" },
  { value: "PhysicalEducation", label: "Physical Education" },
  { value: "Science", label: "Science" },
  { value: "SocialStudies", label: "Social Studies" },
  { value: "Other", label: "Other" },
];

const countiesOptions = [
  { value: "Illinois", label: "Illinois" },
  { value: "Nevada", label: "Nevada" },
  { value: "Oklahoma", label: "Oklahoma" },
];

const jobTitlesOptions = [{ value: "Director", label: "Director" }];

const principalJobTitlesOptions = [{ value: "Principal", label: "Principal" }];

const defaultDateTimeFormat = "LLL";
const defaultDateFormat = "MM/DD/YYYY";
const dbAcceptableFormat = "YYYY-MM-DD";

const alphabeticCharacterRegex = /^[a-zA-Z() ]+$/;

const alphaNumericCharacterRegex = /^[a-zA-Z0-9() ]+$/;

// eslint-disable-next-line
const classRoomNameRegex = /^[a-zA-Z0-9\-\/ ]+$/;

const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/;
/** Password regex combination
 * one digit must
 * one character must (lower or upper)
 * every other things optional
 **/
const FieldsMaxLengths = {
  firstName: 20,
  middleName: 20,
  lastName: 20,
  zip: 11,
  jobTitle: 12,
  phoneNumber: 15,
  fax: 16,
  ncesDistrictID: 18,
  districtName: 30,
  className: 20,
  groupName: 20,
};

const FieldsMinLengths = {
  firstName: 1,
  lastName: 1,
  assignmentName: 3,
  phoneNumber: 9,
  fax: 9,
  districtName: 3,
  className: 2,
  groupName: 2,
  groupStudent: 1,
  classStudent: 1,
  classTeacher: 1,
};

const wildCardRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/;
const facebookUrl =
  // eslint-disable-next-line
  /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
const twitterUrl = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
const instaUrl =
  // eslint-disable-next-line
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
const linkedInURL =
  // eslint-disable-next-line
  /(http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const acceptedVideoFormat =
  "video/lv, video/mp4, video/3gp, video/quicktime, video/x-msvideo, video/x-ms-wmv";
const reservedDomain = ["admin", "api"];

const acceptedImageFormat = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  "image/tiff",
  "image/svg+xml",
  "image/gif",
  "image/x-png",
];

const sectionOptions = [
  { value: "5ffd531cbd8b35e2a3d57478", label: "Grammar" },
  { value: "5ffd531cbd8b35e2a3d57479", label: "Noun" },
];

const acceptedImportFileFormat = ".csv, .xls, .xlsx";

const assignmentStatusOptions = [
  { value: "Incomplete", label: "Incomplete" },
  { value: "Completed", label: "Submitted" },
  { value: "CompletedAfterDueDate", label: "Submitted after due date" },
];

/*
-----------------------------------------------------
 Function to get no of questions added in a subjects
-----------------------------------------------------
*/
const getAddedQuestionsCount = (data, requestType) => {
  if (data && data.length > 0) {
    let count = 0;
    if (requestType === "paragraph") {
      for (let i = 0; i < data.length; i++) {
        // for paragraphs
        if (data[i].questions) {
          for (let j = 0; j < data[i].questions.length; j++) {
            // for questions
            if (data[i].questions[j].isAdded) {
              count++;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        // for questions
        if (data[i].isAdded) {
          count++;
        }
      }
    }
    return count;
  }
};

//get role wise route for profile page update and view
const roleWiseRoute = {
  School: "school",
  ProgramSite: "program-site",
  SchoolSubAdmin: "",
  ProgramSiteSubAdmin: "",
  SchoolTeacher: "teacher",
  ProgramSiteTeacher: "teacher",
};

const getCurrentSubscriptionYear = () => {
  var fiscalyear = "";
  var today = new Date();
  if (today.getMonth() + 1 <= 6) {
    fiscalyear = `${today.getFullYear() - 1}-${today.getFullYear()}`;
  } else {
    fiscalyear = `${today.getFullYear()}-${today.getFullYear() + 1}`;
  }
  return fiscalyear;
};

const getCurrentSubscriptionYearunitWiseTeacher = (responseUnitTeacher) => {
  const currentSubscriptionYear = getCurrentSubscriptionYear();
  for (let i = 0; i < responseUnitTeacher.length; i++) {
    if (responseUnitTeacher[i].subject !== "All Subjects") {
      let teacher = responseUnitTeacher[i].teacher;
      const filterData = teacher.filter(
        (o) => o.subyear?.[0] === currentSubscriptionYear
      );
      responseUnitTeacher[i].teacher = filterData;
    }
  }
  return responseUnitTeacher;
};

const getLastSubscriptionYears = () => {
  var subsArr = [];
  var today = new Date();
  let right = 0;
  let left = 0;

  if (today.getMonth() + 1 <= 6) {
    right = today.getFullYear() - 1;
    left = today.getFullYear();
  } else {
    right = today.getFullYear();
    left = today.getFullYear() + 1;
  }

  right -= 3;
  left -= 3;

  let yearRange = `${right}-${left}`;
  subsArr.push({
    value: yearRange,
    label: yearRange,
  });

  for (let i = 1; i <= 3; i++) {
    right = left;
    left = left + 1;
    yearRange = `${right}-${left}`;
    subsArr.push({
      value: yearRange,
      label: yearRange,
    });
  }

  return subsArr;
};

const getPurchasedLastSubscriptionYears = (purchasedYears) => {
  //let purchasedYears = ["2022-2023", "2023-2024", "2024-2025"];
  purchasedYears = purchasedYears || []
  var subsArr = [];
  var today = new Date();
  let right = 0;
  let left = 0;

  if (today.getMonth() + 1 <= 6) {
    right = today.getFullYear() - 1;
    left = today.getFullYear();
  } else {
    right = today.getFullYear();
    left = today.getFullYear() + 1;
  }

  right -= 3;
  left -= 3;

  let yearRange = `${right}-${left}`;

  if (purchasedYears.includes(yearRange)) {
    subsArr.push({
      value: yearRange,
      label: yearRange,
    });
  }

  for (let i = 1; i <= 3; i++) {
    right = left;
    left = left + 1;
    yearRange = `${right}-${left}`;

    if (purchasedYears.includes(yearRange)) {
      subsArr.push({
        value: yearRange,
        label: yearRange,
      });
    }
  }

  return subsArr;
};

export {
  paginationLimit,
  getDateTime,
  countriesData,
  statesData,
  countiesData,
  citiesData,
  setLabelValue,
  setLabelValue2,
  districtDropDownSet,
  subadminTitleOptions,
  statusOptions,
  classAssignedOptions,
  seatStatusOptions,
  noTimesUsedOptions,
  genderOptions,
  sortOptions,
  subscriptionOptions,
  alphaNumeric,
  ethnicityOptions,
  gradeOptions,
  suffixOptions,
  countiesOptions,
  UpscoreTestOptions,
  TestOptions,
  TestOptionsB,
  yesOptions,
  selectOptions,
  testStatusOptions,
  testNameOptions,
  CourseOptions,
  fullCourseOptions,
  ActScoreOptions,
  QuizScoreOptions,
  timeOptions,
  defaultDateTimeFormat,
  defaultDateFormat,
  dbAcceptableFormat,
  jobTitlesOptions,
  principalJobTitlesOptions,
  alphabeticCharacterRegex,
  alphaNumericCharacterRegex,
  wildCardRegex,
  reservedDomain,
  FieldsMaxLengths,
  FieldsMinLengths,
  passwordRegex,
  sectionOptions,
  facebookUrl,
  instaUrl,
  linkedInURL,
  twitterUrl,
  getAddedQuestionsCount,
  acceptedImageFormat,
  acceptedVideoFormat,
  subjectOptions,
  departmentOptions,
  userRoles,
  roleWiseRoute,
  userRolesForHeader,
  acceptedImportFileFormat,
  paymentStatusOptions,
  passwordSpaceRegex,
  classRoomNameRegex,
  isSeatExpire,
  seatNumberOfTimesOptions,
  assignmentStatusOptions,
  essayOptions,
  // getDateTimeCST,
  getExpyDate,
  formatDateClientTZ,
  getFormatDate,
  getCurrentSubscriptionYear,
  getCurrentSubscriptionYearunitWiseTeacher,
  getLastSubscriptionYears,
  getPurchasedLastSubscriptionYears,
  programSiteSubadminJobTitles,
};
