const ApiRoutes = {
  LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  GET_PROFILE_DETAILS: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  FORGOT_PASSWORD: {
    service: "/auth",
    url: "/forgot-password",
    method: "GET",
    authenticate: true,
  },
  SET_PASSWORD: {
    service: "/auth",
    url: "/set-password/:id",
    method: "PUT",
    authenticate: false,
  },
  VERIFY_TOKEN: {
    service: "/auth",
    url: "/verify-link",
    method: "POST",
    authenticate: false,
  },
  VIEW_PROFILE: {
    service: "/auth",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PROFILE: {
    service: "/auth",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  CHANGE_PASSWORD: {
    service: "/auth",
    url: "/change-password",
    method: "POST",
    authenticate: true,
  },

  GET_SETTINGS: {
    service: "/user",
    url: "/view",
    method: "GET",
    authenticate: true,
  },
  UPDATE_SETTINGS: {
    service: "/user",
    url: "/update",
    method: "POST",
    authenticate: true,
  },
  BASIC_DETAIL_BY_DOMAIN: {
    service: "/auth",
    url: "/get-detail-by-domain",
    method: "GET",
    authenticate: true,
  },
  GET_TEACHERS: {
    service: "/teacher",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_TEACHER: {
    service: "/teacher",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_TEACHER_STATUS: {
    service: "/teacher",
    url: "/status/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_TEACHER: {
    service: "/teacher",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_TEACHER: {
    service: "/teacher",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UNIT_WISE_TEACHER: {
    service: "/teacher/unit-wise-teacher",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_SUBJECTS: {
    service: "/unit",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  DELETE_TEACHER: {
    service: "/teacher",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  IMPORT_TEACHER: {
    service: "/teacher",
    url: "/importTeacher",
    method: "POST",
    authenticate: true,
  },
  MOVE_TEACHER: {
    service: "/teacher",
    url: "/move-forward",
    method: "POST",
    authenticate: true,
  },

  GET_ALL_STUDENTS: {
    service: "/student/all",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENTS: {
    service: "/student",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_STUDENT: {
    service: "/student",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_STUDENT_STATUS: {
    service: "/student",
    url: "/status/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_STUDENT: {
    service: "/student",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_STUDENT: {
    service: "/student",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_STUDENT: {
    service: "/student",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  IMPORT_STUDENT: {
    service: "/student",
    url: "/importStudent",
    method: "POST",
    authenticate: true,
  },
  MOVE_STUDENT: {
    service: "/student",
    url: "/move-forward",
    method: "POST",
    authenticate: true,
  },

  GET_CLASSES: {
    service: "/classroom",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_CLASS: {
    service: "/classroom",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CLASS_STATUS: {
    service: "/classroom",
    url: "/status/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_CLASS: {
    service: "/classroom",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_CLASS: {
    service: "/classroom",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_CLASS: {
    service: "/classroom",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  MOVE_CLASS: {
    service: "/classroom",
    url: "/move-forward",
    method: "POST",
    authenticate: true,
  },

  GET_SUPPORT_REQUESTS: {
    service: "/support",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_SUPPORT_REQUEST: {
    service: "/support",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_SUPPORT_REQUEST_STATUS: {
    service: "/support",
    url: "/status/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_SUPPORT_REQUEST: {
    service: "/support",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_SUPPORT_REQUEST: {
    service: "/support",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_GROUPS: {
    service: "/group",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_GROUP: {
    service: "/group",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_GROUP_STATUS: {
    service: "/group",
    url: "/status/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_GROUP: {
    service: "/group",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_GROUP: {
    service: "/group",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_GROUP: {
    service: "/group",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  MOVE_GROUP: {
    service: "/group",
    url: "/move-forward",
    method: "POST",
    authenticate: true,
  },

  GET_SUBADMINS: {
    service: "/subadmin",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_SUBADMIN: {
    service: "/subadmin",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_SUBADMIN_STATUS: {
    service: "/subadmin",
    url: "/status/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_SUBADMIN: {
    service: "/subadmin",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_SUBADMIN: {
    service: "/subadmin",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_SUBADMIN: {
    service: "/subadmin",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  MOVE_SUBADMIN: {
    service: "/subadmin",
    url: "/move-forward",
    method: "POST",
    authenticate: true,
  },

  /* Order History */
  GET_ORDER_HISTORY: {
    service: "/checkout/org-transactions",
    url: "/",
    method: "GET",
    authenticate: true,
  },

  /*
  -------------------------------------
                Subscribed plans
  -------------------------------------
  */

  GET_SUBSCRIBED_PLAN: {
    service: "/auth",
    url: "/subscribed-plan",
    method: "GET",
    authenticate: true,
  },

  /*
  -------------------------------------
                Class and Group list
  -------------------------------------
  */

  GET_CLASS_GROUP: {
    service: "/student",
    url: "/classAndGroup",
    method: "GET",
    authenticate: true,
  },

  /*
  -------------------------------------
                Seat Management
  -------------------------------------
  */

  GET_SEATS: {
    service: "/",
    url: "/seat",
    method: "GET",
    authenticate: true,
  },

  GET_UNASSSIGNED_STUDENT: {
    service: "/seat/studentList",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_SEAT: {
    service: "/seat",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  REMOVE_SEAT_ACCESS: {
    service: "/seat",
    url: "/removeAccess/:id",
    method: "PATCH",
    authenticate: true,
  },

  /*
  -------------------------------------
                Assignment Management
  -------------------------------------
  */

  GET_ASSIGNMENTS: {
    service: "/assignment",
    url: "/",
    method: "GET",
    authenticate: true,
  },

  VIEW_ASSIGNMENT: {
    service: "/assignment",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },

  GET_ASSIGNMENT_CLASSES: {
    service: "/classroom/classesByTeacher",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_LESSON: {
    service: "/assignment/students-and-lessons",
    url: "/:class_id",
    method: "GET",
    authenticate: true,
  },
  ADD_ASSIGNMENT: {
    service: "/assignment",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_ASSIGNMENT: {
    service: "/assignment",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_ASSIGNMENTS: {
    service: "/assignment",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },

  GET_NOTIFICATION: {
    service: "/notification",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_NOTIFICATION_STATUS: {
    service: "/notification",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_NOTIFICATION_COUNT: {
    service: "/notification",
    url: "/update-count",
    method: "PUT",
    authenticate: true,
  },

  GET_ESSAYS: {
    service: "/essay",
    url: "/list",
    method: "GET",
    authenticate: true,
  },
  VIEW_ESSAY: {
    service: "/essay",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  ADD_ESSAY: {
    service: "/essay",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_ESSAY: {
    service: "/essay",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_USER_COUNT: {
    service: "/dashboard",
    url: "/all-user-count",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_COUNT: {
    service: "/dashboard",
    url: "/student-count",
    method: "GET",
    authenticate: true,
  },
  GET_SEAT_COUNT: {
    service: "/dashboard",
    url: "/seat-count",
    method: "GET",
    authenticate: true,
  },
  GET_CLASS_COUNT: {
    service: "/dashboard",
    url: "/class-count",
    method: "GET",
    authenticate: true,
  },
  GET_GROUP_COUNT: {
    service: "/dashboard",
    url: "/group-count",
    method: "GET",
    authenticate: true,
  },
  GET_SUPPORT_COUNT: {
    service: "/dashboard",
    url: "/support-count",
    method: "GET",
    authenticate: true,
  },
  GET_TEACHER_COUNT: {
    service: "/dashboard",
    url: "/teacher-count",
    method: "GET",
    authenticate: true,
  },
  GET_TEACHER_STUDENT_COUNT: {
    service: "/dashboard",
    url: "/teachers-student-count",
    method: "GET",
    authenticate: true,
  },
  GET_TEACHER_ASSIGNMENT_COUNT: {
    service: "/dashboard",
    url: "/teacher-assignment-count",
    method: "GET",
    authenticate: true,
  },
  GET_ESSAY_COUNT: {
    service: "/dashboard",
    url: "/essay-count",
    method: "GET",
    authenticate: true,
  },

  /* ***Linkage Request*** */
  GENERATE_LINKAGE: {
    service: "/district-program",
    url: "/linkage-request",
    method: "POST",
    authenticate: true,
  },
  LIST_LINKAGE_REQUEST: {
    service: "/district-program",
    url: "/list-org-request",
    method: "GET",
    authenticate: true,
  },
  LIST_PROGRAM_DISTRICT: {
    service: "/district-program",
    url: "/list",
    method: "POST",
    authenticate: true,
  },
  ATTEMPT_REQUEST: {
    service: "/district-program",
    url: "/attempt-request/:id",
    method: "PUT",
    authenticate: true,
  },
  UNLINK_LINKAGE: {
    service: "/district-program",
    url: "/unlink/:id",
    method: "PUT",
    authenticate: true,
  },
  /* ***Report Request*** */
  GET_STUDENT_LIST: {
    service: "/report",
    url: "/list-organization-student",
    method: "GET",
    authenticate: true,
  },
  GET_CLASS_LIST: {
    service: "/report",
    url: "/list-classroom",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_TEST_REPORT: {
    service: "/report",
    url: "/org-test-completion",
    method: "GET",
    authenticate: true,
  },

  GET_STUDENT_COMPARISON_REPORT: {
    service: "/report",
    url: "/org-act-score-comparison",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_ACT_PERFORMANCE_REPORT: {
    service: "/report",
    url: "/org-act-performance",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_ACT_DETAILED_REPORT: {
    service: "/report",
    url: "/org-act-detailed",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_QUIZ_PROGRESS_REPORT: {
    service: "/report",
    url: "/org-quiz-progress-score",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_QUIZ_PROGRESS_TEST_SCORE_REPORT: {
    service: "/report",
    url: "/org-quiz-progress-test-score",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT: {
    service: "/report",
    url: "/org-student-course-progress-overview",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT: {
    service: "/report",
    url: "/org-student-act-score-progress-overview",
    method: "GET",
    authenticate: true,
  },
  GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT: {
    service: "/report",
    url: "/org-student-quiz-progress-overview",
    method: "GET",
    authenticate: true,
  },
  /* ***Subscription Year only*** */
  GET_SUBSCRIPTION_YEAR: {
    service: "/subscriptionYear",
    url: "/list-subscriptionyear",
    method: "GET",
    authenticate: true,
  }
};

export default ApiRoutes;
