/** @format */

import { LinkageReqInitialStates } from "../states";
import { LinkageActionTypes } from "../actions";

export const LinkageReducer = (state = LinkageReqInitialStates, action) => {
  switch (action.type) {
    case LinkageActionTypes.LINKAGE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        requestListed: false,
        error: "",
      };
    case LinkageActionTypes.LINKAGE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestListed: true,
        requests: action.payload.requests,
      };
    case LinkageActionTypes.LINKAGE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case LinkageActionTypes.DISTRICT_PROGRAM_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case LinkageActionTypes.DISTRICT_PROGRAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        districtPrograms: action.payload.districtPrograms,
      };
    case LinkageActionTypes.DISTRICT_PROGRAM_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case LinkageActionTypes.LINKAGE_GENERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case LinkageActionTypes.LINKAGE_GENERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LinkageActionTypes.LINKAGE_GENERATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case LinkageActionTypes.LINKAGE_ATTEMPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case LinkageActionTypes.LINKAGE_ATTEMPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitting: true,
        isUpdated: true,
      };
    case LinkageActionTypes.LINKAGE_ATTEMPT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSubmitting: true,
        error: action.error,
      };

    case LinkageActionTypes.LINKAGE_UNLINK_REQUEST:
      return {
        ...state,
        isLoading: true,

        error: "",
      };
    case LinkageActionTypes.LINKAGE_UNLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LinkageActionTypes.LINKAGE_UNLINK_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
