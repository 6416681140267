import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getSeats = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_SEATS.service,
    ApiRoutes.GET_SEATS.url,
    ApiRoutes.GET_SEATS.method,
    ApiRoutes.GET_SEATS.authenticate,
    payload,
    undefined
  );
  return response;
};

const updateSeat = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_SEAT.service,
    ApiRoutes.UPDATE_SEAT.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_SEAT.method,
    ApiRoutes.UPDATE_SEAT.authenticate,
    undefined,
    payload
  );
  return response;
};

const removeAccess = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.REMOVE_SEAT_ACCESS.service,
    ApiRoutes.REMOVE_SEAT_ACCESS.url.replace(":id", payload.id),
    ApiRoutes.REMOVE_SEAT_ACCESS.method,
    ApiRoutes.REMOVE_SEAT_ACCESS.authenticate,
    undefined,
    payload
  );
  return response;
};

export const seatService = {
  getSeats,
  updateSeat,
  removeAccess,
};
