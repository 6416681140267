import { DashboardInitialStates } from "../states";
import { DashboardActionTypes } from "../actions";

export const DashboardReducer = (state = DashboardInitialStates, action) => {
  switch (action.type) {
    //sidebar toggle
    case DashboardActionTypes.SET_SIDEBAR_SHOW:
      return {
        ...state,
        sidebarShow: action.payload,
      };
    // user count
    case DashboardActionTypes.ALL_USER_COUNT_REQUEST:
      return {
        ...state,
        isAllUserLoading: true,
        error: "",
      };
    case DashboardActionTypes.ALL_USER_COUNT_SUCCESS:
      return {
        ...state,
        isAllUserLoading: false,
        allUserCount: action.payload.allUserCount,
        error: "",
      };
    case DashboardActionTypes.ALL_USER_COUNT_FAILED:
      return {
        ...state,
        isAllUserLoading: false,
        error: action.error,
      };

    // student count
    case DashboardActionTypes.STUDENT_COUNT_REQUEST:
      return {
        ...state,
        isStudentCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.STUDENT_COUNT_SUCCESS:
      return {
        ...state,
        isStudentCountLoading: false,
        studentCount: action.payload.studentCount,
        error: "",
      };
    case DashboardActionTypes.STUDENT_COUNT_FAILED:
      return {
        ...state,
        isStudentCountLoading: false,
        error: action.error,
      };

    // seat count
    case DashboardActionTypes.SEAT_COUNT_REQUEST:
      return {
        ...state,
        isSeatCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.SEAT_COUNT_SUCCESS:
      return {
        ...state,
        isSeatCountLoading: false,
        seatCount: action.payload.seatCount,
        error: "",
      };
    case DashboardActionTypes.SEAT_COUNT_FAILED:
      return {
        ...state,
        isSeatCountLoading: false,
        error: action.error,
      };

    // class count
    case DashboardActionTypes.CLASS_COUNT_REQUEST:
      return {
        ...state,
        isClassCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.CLASS_COUNT_SUCCESS:
      return {
        ...state,
        isClassCountLoading: false,
        classCount: action.payload.classCount,
        error: "",
      };
    case DashboardActionTypes.CLASS_COUNT_FAILED:
      return {
        ...state,
        isClassCountLoading: false,
        error: action.error,
      };

    // group count
    case DashboardActionTypes.GROUP_COUNT_REQUEST:
      return {
        ...state,
        isGroupCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.GROUP_COUNT_SUCCESS:
      return {
        ...state,
        isGroupCountLoading: false,
        groupCount: action.payload.groupCount,
        error: "",
      };
    case DashboardActionTypes.GROUP_COUNT_FAILED:
      return {
        ...state,
        isGroupCountLoading: false,
        error: action.error,
      };

    // support count
    case DashboardActionTypes.SUPPORT_COUNT_REQUEST:
      return {
        ...state,
        isSupportCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.SUPPORT_COUNT_SUCCESS:
      return {
        ...state,
        isSupportCountLoading: false,
        supportCount: action.payload.supportCount,
        error: "",
      };
    case DashboardActionTypes.SUPPORT_COUNT_FAILED:
      return {
        ...state,
        isSupportCountLoading: false,
        error: action.error,
      };

    // teacher count

    case DashboardActionTypes.TEACHER_COUNT_REQUEST:
      return {
        ...state,
        isTeacherCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.TEACHER_COUNT_SUCCESS:
      return {
        ...state,
        isTeacherCountLoading: false,
        teacherCount: action.payload.teacherCount,
        error: "",
      };
    case DashboardActionTypes.TEACHER_COUNT_FAILED:
      return {
        ...state,
        isTeacherCountLoading: false,
        error: action.error,
      };

    // essay count

    case DashboardActionTypes.ESSAY_COUNT_REQUEST:
      return {
        ...state,
        isEssayCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.ESSAY_COUNT_SUCCESS:
      return {
        ...state,
        isEssayCountLoading: false,
        essayCount: action.payload.essayCount,
        error: "",
      };
    case DashboardActionTypes.ESSAY_COUNT_FAILED:
      return {
        ...state,
        isEssayCountLoading: false,
        error: action.error,
      };

    // teacher student count

    case DashboardActionTypes.TEACHER_STUDENT_COUNT_REQUEST:
      return {
        ...state,
        isTeacherStudentCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.TEACHER_STUDENT_COUNT_SUCCESS:
      return {
        ...state,
        isTeacherStudentCountLoading: false,
        teacherStudentCount: action.payload.teacherStudentCount,
        error: "",
      };
    case DashboardActionTypes.TEACHER_STUDENT_COUNT_FAILED:
      return {
        ...state,
        isTeacherStudentCountLoading: false,
        error: action.error,
      };

    // teacher assignment count

    case DashboardActionTypes.TEACHER_ASSIGNMENT_COUNT_REQUEST:
      return {
        ...state,
        isAssignmentCountLoading: true,
        error: "",
      };
    case DashboardActionTypes.TEACHER_ASSIGNMENT_COUNT_SUCCESS:
      return {
        ...state,
        isAssignmentCountLoading: false,
        teacherAssignmentCount: action.payload.teacherAssignmentCount,
        error: "",
      };
    case DashboardActionTypes.TEACHER_ASSIGNMENT_COUNT_FAILED:
      return {
        ...state,
        isAssignmentCountLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
