/** @format */
import * as Yup from "yup";
import { message } from "../common";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address")),
    // .email(message.InvalidEmail),
  password: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Password"))
    /* .min(8, message.minLengthPassword)
    .max(
      18,
      message.MaxLengthError.replace(":item", "Password").replace(":length", 18)
    )
    .matches(passwordSpaceRegex, message.SpaceError)
    .matches(passwordRegex, message.InvalidPassword), */
});
