/** @format */
import * as Yup from "yup";
import { message } from "../common";

export const SupportRequestSchema = Yup.object().shape({
  subject: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Subject")),
  message: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Message")),
});
