import { ModalInitialStates } from "../states";
import { ModelActionTypes } from "../actions";

export const ModalInfoReducer = (state = ModalInitialStates, action) => {
  switch (action.type) {
    case ModelActionTypes.MODAL_TOGGLE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
