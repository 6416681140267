/** @format */

import { SubscriptionYearService } from "../services";
import { showError } from "../../components/MessageHandler";

export const SubscrpitionYearActionTypes = {
  SUBSCRIPTION_YEAR_LIST_REQUEST: "Subscription Year Listing Request!",
  SUBSCRIPTION_YEAR_LIST_SUCCESS: "Subscription Year Listed Successfully!",
  SUBSCRIPTION_YEAR_LIST_FAILED: "Subscription Year Listing Failed!",
};

const getSubscriptionYear = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await SubscriptionYearService.getSubscriptionYear(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };
  function request() {
    return { type: SubscrpitionYearActionTypes.SUBSCRIPTION_YEAR_LIST_REQUEST };
  }
  function success(responseData) {
    return {
      type: SubscrpitionYearActionTypes.SUBSCRIPTION_YEAR_LIST_SUCCESS,
      payload: {
          Data: responseData.Data,
        },
    };      
  }
  function failure(error) {
    return { type: SubscrpitionYearActionTypes.SUBSCRIPTION_YEAR_LIST_FAILED, error };
  }
};

export const SubscriptionYearActions = {
    getSubscriptionYear,
};
