/** @format */

import { SubjectInitialStates } from "../states";
import { SubjectActionTypes } from "../actions";

export const SubjectReducer = (state = SubjectInitialStates, action) => {
  switch (action.type) {
    case SubjectActionTypes.SUBJECT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case SubjectActionTypes.SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subjects: action.payload.subjects,
        error: "",
      };
    case SubjectActionTypes.SUBJECT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
