import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getGroups = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_GROUPS.service,
    ApiRoutes.GET_GROUPS.url,
    ApiRoutes.GET_GROUPS.method,
    ApiRoutes.GET_GROUPS.authenticate,
    payload,
    undefined
  );
  return response;
};

const viewGroup = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VIEW_GROUP.service,
    ApiRoutes.VIEW_GROUP.url.replace(":id", payload.id),
    ApiRoutes.VIEW_GROUP.method,
    ApiRoutes.VIEW_GROUP.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateGroupStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_GROUP_STATUS.service,
    ApiRoutes.UPDATE_GROUP_STATUS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_GROUP_STATUS.method,
    ApiRoutes.UPDATE_GROUP_STATUS.authenticate,
    undefined,
    payload
  );
  return response;
};

const addGroup = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_GROUP.service,
    ApiRoutes.ADD_GROUP.url,
    ApiRoutes.ADD_GROUP.method,
    ApiRoutes.ADD_GROUP.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateGroup = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_GROUP.service,
    ApiRoutes.UPDATE_GROUP.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_GROUP.method,
    ApiRoutes.UPDATE_GROUP.authenticate,
    undefined,
    payload
  );
  return response;
};

const deleteGroup = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.DELETE_GROUP.service,
    ApiRoutes.DELETE_GROUP.url.replace(":id", payload.id),
    ApiRoutes.DELETE_GROUP.method,
    ApiRoutes.DELETE_GROUP.authenticate,
    undefined,
    undefined
  );
  return response;
};

const moveForwardGroup = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.MOVE_GROUP.service,
    ApiRoutes.MOVE_GROUP.url,
    ApiRoutes.MOVE_GROUP.method,
    ApiRoutes.MOVE_GROUP.authenticate,
    undefined,
    payload
  );
  return response;
};

export const groupService = {
  getGroups,
  viewGroup,
  updateGroupStatus,
  addGroup,
  updateGroup,
  deleteGroup,
  moveForwardGroup,
};
