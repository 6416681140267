import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getAllUserCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_USER_COUNT.service,
    ApiRoutes.GET_USER_COUNT.url,
    ApiRoutes.GET_USER_COUNT.method,
    ApiRoutes.GET_USER_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_COUNT.service,
    ApiRoutes.GET_STUDENT_COUNT.url,
    ApiRoutes.GET_STUDENT_COUNT.method,
    ApiRoutes.GET_STUDENT_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getSeatCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_SEAT_COUNT.service,
    ApiRoutes.GET_SEAT_COUNT.url,
    ApiRoutes.GET_SEAT_COUNT.method,
    ApiRoutes.GET_SEAT_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getClassCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_CLASS_COUNT.service,
    ApiRoutes.GET_CLASS_COUNT.url,
    ApiRoutes.GET_CLASS_COUNT.method,
    ApiRoutes.GET_CLASS_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getGroupCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_GROUP_COUNT.service,
    ApiRoutes.GET_GROUP_COUNT.url,
    ApiRoutes.GET_GROUP_COUNT.method,
    ApiRoutes.GET_GROUP_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getSupportCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_SUPPORT_COUNT.service,
    ApiRoutes.GET_SUPPORT_COUNT.url,
    ApiRoutes.GET_SUPPORT_COUNT.method,
    ApiRoutes.GET_SUPPORT_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getTeacherCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_TEACHER_COUNT.service,
    ApiRoutes.GET_TEACHER_COUNT.url,
    ApiRoutes.GET_TEACHER_COUNT.method,
    ApiRoutes.GET_TEACHER_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getEssayCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_ESSAY_COUNT.service,
    ApiRoutes.GET_ESSAY_COUNT.url,
    ApiRoutes.GET_ESSAY_COUNT.method,
    ApiRoutes.GET_ESSAY_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getTeacherStudentCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_TEACHER_STUDENT_COUNT.service,
    ApiRoutes.GET_TEACHER_STUDENT_COUNT.url,
    ApiRoutes.GET_TEACHER_STUDENT_COUNT.method,
    ApiRoutes.GET_TEACHER_STUDENT_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getTeacherAssignmentCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_TEACHER_ASSIGNMENT_COUNT.service,
    ApiRoutes.GET_TEACHER_ASSIGNMENT_COUNT.url,
    ApiRoutes.GET_TEACHER_ASSIGNMENT_COUNT.method,
    ApiRoutes.GET_TEACHER_ASSIGNMENT_COUNT.authenticate,
    payload,
    undefined
  );
  return response;
};

export const dashboardRequestService = {
  getAllUserCount,
  getStudentCount,
  getSeatCount,
  getClassCount,
  getGroupCount,
  getSupportCount,
  getTeacherCount,
  getTeacherAssignmentCount,
  getTeacherStudentCount,
  getEssayCount,
};
