/** @format */

import {
  AuthInitialStates,
  ResetPasswordInitialStates,
  ForgotPasswordInitialStates,
} from "./../states";
import { AuthActionTypes } from "../actions";

export const AuthReducer = (state = AuthInitialStates, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isloggedIn: true,
        isLoading: false,
        user: action.user,
        error: "",
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isloggedIn: false,
        isLoading: false,
        error: action.error,
      };
    case AuthActionTypes.LOGOUT:
      return {};

    case AuthActionTypes.GET_USER_DETAILS:
      return {
        ...state,
        isLoading: true,
        isUserDetailsLoading: true,
        isAuthenticated: false,
        userDetails: [],
      };
    case AuthActionTypes.USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUserDetailsLoading: false,
        isAuthenticated: true,
        userDetails: action.payload.user,
        planDetails: action.payload.plan,
        access: action.payload.access,
        subscriptionYears: action.payload.subscriptionYears,
      };
    case AuthActionTypes.USER_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        isUserDetailsLoading: false,
        isAuthenticated: false,
        userDetails: [],
      };
    case AuthActionTypes.VERIFY_TOKEN_REQUEST:
      return {
        ...state,
        verifyToken: true,
        verifyTokenLoading: true,
        verifiedData: [],
      };
    case AuthActionTypes.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        verifyToken: true,
        verifyTokenLoading: false,
        verifiedData: action.payload,
      };
    case AuthActionTypes.VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        verifyToken: false,
        verifyTokenLoading: false,
        verifiedData: [],
      };
    default:
      return state;
  }
};

export const ResetPasswordReducer = (
  state = ResetPasswordInitialStates,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.SET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isPasswordSet: false,
        error: "",
      };
    case AuthActionTypes.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordSet: true,
        error: "",
      };
    case AuthActionTypes.SET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const ForgotPasswordReducer = (
  state = ForgotPasswordInitialStates,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.REQUEST_FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case AuthActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case AuthActionTypes.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
