/** @format */
import * as Yup from "yup";
import {
  FieldsMaxLengths,
  FieldsMinLengths,
  message,
  alphabeticCharacterRegex,
  alphaNumericCharacterRegex,
} from "../common";

export const ProgramSitesSchema = Yup.object().shape({
  programSiteName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Program site name")),

  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),

  userName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Username")),

  phoneNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Phone number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  faxNumber: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),

  // Super Admin
  superAdminFirstName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "First name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    ),
  superAdminLastName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Last name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    ),

  superAdminJobTitle: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .test(
      "superAdminJobTitle",
      message.InvalidNameNumber.replace(":item", "Job title"),
      function (value) {
        if (!!value) {
          const schema = Yup.string().matches(alphaNumericCharacterRegex);
          return schema.isValidSync(value);
        }
        return true;
      }
    )
    .test(
      "jobTitleLength",
      message.MaxLengthError.replace(":item", "Job title").replace(
        ":length",
        FieldsMaxLengths.jobTitle
      ),
      function (value) {
        if (!!value) {
          const schema = Yup.string().max(FieldsMaxLengths.jobTitle);
          return schema.isValidSync(value);
        }
        return true;
      }
    ),
  superAdminEmail: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  superAdminPhoneNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Phone number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  superAdminFax: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),

  // site Director
  contactPersonEmail: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  contactPersonFirstName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "First name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    ),
  contactPersonLastName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Last name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    ),
  contactPersonPhoneNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Phone number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  contactPersonFax: Yup.string()
    .trim()
    // .required(message.Required.replace(':item', 'Fax'))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),

  physicalAddress: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Physical address")),

  physicalState: Yup.mixed().required(
    message.Required.replace(":item", "Physical state")
  ),
  physicalCounty: Yup.mixed().required(
    message.Required.replace(":item", "Physical county")
  ),
  physicalCity: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Physical city")),

  physicalZip: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Physical zip code")),

  mailingAddress: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Mailing address")),

  mailingState: Yup.mixed().required(
    message.Required.replace(":item", "Mailing state")
  ),
  mailingCounty: Yup.mixed().required(
    message.Required.replace(":item", "Mailing county")
  ),

  mailingCity: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Mailing city")),
  mailingZip: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Mailing zip code")),
});
