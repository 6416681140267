/** @format */

import { subjectService } from "../services";
import { showError } from "../../components/MessageHandler";

export const SubjectActionTypes = {
  SUBJECT_LIST_REQUEST: "Subject Listing Request!",
  SUBJECT_LIST_SUCCESS: "Subject Listed Successfully!",
  SUBJECT_LIST_FAILED: "Subject Listing Failed!",
};

const getSubjects = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await subjectService.getSubjects(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };
  function request() {
    return { type: SubjectActionTypes.SUBJECT_LIST_REQUEST };
  }
  function success(responseData) {
    return {
      type: SubjectActionTypes.SUBJECT_LIST_SUCCESS,
      payload: {
        subjects: responseData.data,
        totalRecords: responseData.totalRecords || responseData.data.length,
      },
    };
  }
  function failure(error) {
    return { type: SubjectActionTypes.SUBJECT_LIST_FAILED, error };
  }
};

export const subjectActions = {
  getSubjects,
};
