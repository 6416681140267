export const GroupInitialStates = {
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
  isSubmitting: false,
  error: false,
  groups: [],
  groupData: {},
  totalRecords: 0,
};
