import { push } from "react-router-redux";
export * from "./Auth";
export * from "./Teacher";
export * from "./Modal";
export * from "./Subject";
export * from "./Student";
export * from "./Class";
export * from "./SupportRequest";
export * from "./Group";
export * from "./Subadmin";
export * from "./OrderHistory";
export * from "./Seat";
export * from "./Assignment";
export * from "./Notification";
export * from "./Essay";
export * from "./Dashboard";
export * from "./LinkageRequest";
export * from "./Report";
export * from "./SubscriptionYear";

export const redirectToAction = (payload) => {
  return async (dispatch) => {
    dispatch(push(payload.path));
  };
};
