export const TeacherInitialStates = {
  isLoading: true,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
  isSubmitting: false,
  error: false,
  unitWiseTeachers: [],
  teachers: [],
  teacherData: {},
  totalRecords: 0,
  errorData: [],
  isImportLoading: false,
  isImported: false,
  isImportSuccess: false,
  isImportMessage: "",
};
