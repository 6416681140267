import { showError } from "../../components/MessageHandler";
import { reportRequestService } from "../services";

export const ReportActionTypes = {
  REQUEST_STUDENTS_LIST: "Request to fetch Students List",
  STUDENTS_LIST_SUCCESS: "Students List fetched successfully",
  STUDENTS_LIST_FAILED: "Failed to fetched students List",

  REQUEST_CLASS_LIST: "Request to fetch Class List",
  CLASS_LIST_SUCCESS: "Class List fetched successfully",
  CLASS_LIST_FAILED: "Failed to fetched Class List",

  REQUEST_STUDENTS_TEST_COMPLETION_LIST:
    "Request to fetch Students Test Completion  List",
  STUDENT_TEST_COMPLETION_LIST_SUCCESS:
    "Students Test Completion  List fetched successfully",
  STUDENT_TEST_COMPLETION_LIST_FAILED:
    "Failed to fetched students Test Completion  List",

  REQUEST_STUDENTS_SCORE_COMPARISON_LIST:
    "Request to fetch Students Act Score Comparison List",
  STUDENTS_SCORE_COMPARISON_LIST_SUCCESS:
    "Students Act Score Comparison List fetched successfully",
  STUDENTS_SCORE_COMPARISON_LIST_FAILURE:
    "Failed to fetched students Act Score Comparison List",

  REQUEST_STUDENTS_ACT_PERFORMANCE_LIST:
    "Request to fetch Students Act Performance List",
  STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS:
    "Students Act Performance List fetched successfully",
  STUDENTS_ACT_PERFORMANCE_LIST_FAILURE:
    "Failed to fetched students Act Performance List",

  REQUEST_STUDENTS_QUIZ_PROGRESS_LIST:
    "Request to fetch Students Quiz Progress List",
  STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS:
    "Students Quiz Progress List fetched successfully",
  STUDENTS_QUIZ_PROGRESS_LIST_FAILURE:
    "Failed to fetched students Quiz Progress List",

  REQUEST_STUDENTS_ACT_DETAILED_LIST:
    "Request to fetch Students Act Detailed List",
  STUDENTS_ACT_DETAILED_LIST_SUCCESS:
    "Students Act Detailed List fetched successfully",
  STUDENTS_ACT_DETAILED_LIST_FAILURE:
    "Failed to fetched students Act Detailed List",

  REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST:
    "Request to fetch Students Course Progress Overview List",
  STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    "Students Course Progress Overview List fetched successfully",
  STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE:
    "Failed to fetched students Course Progress Overview List",

  REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST:
    "Request to fetch Students ACT Score Progress Overview List",
  STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    "Students ACT Score Progress Overview List fetched successfully",
  STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
    "Failed to fetched students ACT Score Progress Overview List",

  REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST:
    "Request to fetch Students Quiz Score Progress Overview List",
  STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
    "Students Quiz Score Progress Overview List fetched successfully",
  STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
    "Failed to fetched students Quiz Score Progress Overview List",
};

// all student report
const getAllStudentList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getAllStudentList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_STUDENTS_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_LIST_SUCCESS,
      payload: {
        studentList: responseData.data,
        assignStudentList: responseData.assignStudentData,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_LIST_FAILED,
      error,
    };
  }
};

const getAllClassList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getAllClassList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_CLASS_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.CLASS_LIST_SUCCESS,
      payload: {
        classList: responseData.data,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.CLASS_LIST_FAILED,
      error,
    };
  }
};

// all student report
const getStudentTestReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentTestReportList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_STUDENTS_TEST_COMPLETION_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENT_TEST_COMPLETION_LIST_SUCCESS,
      payload: {
        studentTestReportList: responseData.data,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENT_TEST_COMPLETION_LIST_FAILED,
      error,
    };
  }
};

// score comparison report
const getStudentScoreComparisonReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentScoreComparisonReportList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_STUDENTS_SCORE_COMPARISON_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_SCORE_COMPARISON_LIST_SUCCESS,
      payload: {
        studentReportList: responseData.data,
        totalStudent: responseData.totalStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_SCORE_COMPARISON_LIST_FAILURE,
      error,
    };
  }
};

// act performance report
const getStudentActPerformanceReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentActPerformanceReportList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_STUDENTS_ACT_PERFORMANCE_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS,
      payload: {
        studentReportList: responseData.data,
        totalStudent: responseData.totalStudent,
        testStudent: responseData.testStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_ACT_PERFORMANCE_LIST_FAILURE,
      error,
    };
  }
};

// quiz progress report
const getStudentQuizProgressReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentQuizProgressReportList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_STUDENTS_QUIZ_PROGRESS_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS,
      payload: {
        studentReportList: responseData.data,
        totalStudent: responseData.totalStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_QUIZ_PROGRESS_LIST_FAILURE,
      error,
    };
  }
};

// act Detailed report
const getStudentActDetailedReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentActDetailedReportList(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ReportActionTypes.REQUEST_STUDENTS_ACT_DETAILED_LIST };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_ACT_DETAILED_LIST_SUCCESS,
      payload: {
        studentReportList: responseData.report,
        totalStudent: responseData.totalStudent,
        testStudent: responseData.testStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_ACT_DETAILED_LIST_FAILURE,
      error,
    };
  }
};

// Course Progress Overview report
const getStudentCourseProgressOverviewReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentCourseProgressOverviewReportList(
      payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return {
      type: ReportActionTypes.REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST,
    };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS,
      payload: {
        studentCourseReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE,
      error,
    };
  }
};

// ACT Score Progress Overview report
const getStudentActScoreProgressOverviewReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentActScoreProgressOverviewReportList(
      payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return {
      type: ReportActionTypes.REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST,
    };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS,
      payload: {
        studentActScoreReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE,
      error,
    };
  }
};

// quiz Score Progress Overview report
const getStudentQuizScoreProgressOverviewReportList = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await reportRequestService.getStudentQuizScoreProgressOverviewReportList(
      payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return {
      type: ReportActionTypes.REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST,
    };
  }

  function success(responseData) {
    return {
      type: ReportActionTypes.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS,
      payload: {
        studentQuizScoreReportData: responseData.data,
        totalStudent: responseData.totalStudent,
      },
    };
  }

  function failure(error) {
    return {
      type: ReportActionTypes.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE,
      error,
    };
  }
};

export const reportActions = {
  getStudentTestReportList,
  getStudentScoreComparisonReportList,
  getAllStudentList,
  getAllClassList,
  getStudentActPerformanceReportList,
  getStudentQuizProgressReportList,
  getStudentActDetailedReportList,
  getStudentCourseProgressOverviewReportList,
  getStudentActScoreProgressOverviewReportList,
  getStudentQuizScoreProgressOverviewReportList,
};
