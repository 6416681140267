/** @format */

import { SubadminInitialStates } from "../states";
import { SubadminActionTypes } from "../actions";

export const SubadminReducer = (state = SubadminInitialStates, action) => {
  switch (action.type) {
    case SubadminActionTypes.SUBADMIN_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSubmitting: false,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        subadmins: action.payload.subadmins,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        error: action.error,
      };

    case SubadminActionTypes.SUBADMIN_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isDeleted: false,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subadminData: action.payload.subadminData,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SubadminActionTypes.SUBADMIN_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case SubadminActionTypes.SUBADMIN_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SubadminActionTypes.SUBADMIN_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case SubadminActionTypes.DELETE_SUBADMIN_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };

    case SubadminActionTypes.SUBADMIN_MOVE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_MOVE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: "",
      };
    case SubadminActionTypes.SUBADMIN_MOVE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    default:
      return state;
  }
};
