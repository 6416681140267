import { ReportInitialStates } from "../states";
import { ReportActionTypes } from "../actions";

export const ReportReducer = (state = ReportInitialStates, action) => {
  switch (action.type) {
    case ReportActionTypes.REQUEST_STUDENTS_LIST:
      return {
        ...state,
        isStudentListLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_LIST_SUCCESS:
      return {
        ...state,
        isStudentListLoading: false,
        studentList: action.payload.studentList,
        assignStudentList: action.payload.assignStudentList,
        error: "",
      };
    case ReportActionTypes.STUDENTS_LIST_FAILED:
      return {
        ...state,
        isStudentListLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_CLASS_LIST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case ReportActionTypes.CLASS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classList: action.payload.classList,
        error: "",
      };
    case ReportActionTypes.CLASS_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    // Test completion report

    case ReportActionTypes.REQUEST_STUDENTS_TEST_COMPLETION_LIST:
      return {
        ...state,
        isTestCompletionLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENT_TEST_COMPLETION_LIST_SUCCESS:
      return {
        ...state,
        isTestCompletionLoading: false,
        studentTestReportList: action.payload.studentTestReportList,
        error: "",
      };
    case ReportActionTypes.STUDENT_TEST_COMPLETION_LIST_FAILED:
      return {
        ...state,
        isTestCompletionLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_SCORE_COMPARISON_LIST:
      return {
        ...state,
        isScoreComparisonLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_SCORE_COMPARISON_LIST_SUCCESS:
      return {
        ...state,
        isScoreComparisonLoading: false,
        studentReportList: action.payload.studentReportList,
        totalStudent: action.payload.totalStudent,
        //testStudent: action.payload.testStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_SCORE_COMPARISON_LIST_FAILURE:
      return {
        ...state,
        isScoreComparisonLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_ACT_PERFORMANCE_LIST:
      return {
        ...state,
        isActPerformanceLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_ACT_PERFORMANCE_LIST_SUCCESS:
      return {
        ...state,
        isActPerformanceLoading: false,
        studentReportList: action.payload.studentReportList,
        totalStudent: action.payload.totalStudent,
        testStudent: action.payload.testStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_ACT_PERFORMANCE_LIST_FAILURE:
      return {
        ...state,
        isActPerformanceLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_QUIZ_PROGRESS_LIST:
      return {
        ...state,
        isQuizProgressLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_QUIZ_PROGRESS_LIST_SUCCESS:
      return {
        ...state,
        isQuizProgressLoading: false,
        studentReportList: action.payload.studentReportList,
        totalStudent: action.payload.totalStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_QUIZ_PROGRESS_LIST_FAILURE:
      return {
        ...state,
        isQuizProgressLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_ACT_DETAILED_LIST:
      return {
        ...state,
        isActDetailLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_ACT_DETAILED_LIST_SUCCESS:
      return {
        ...state,
        isActDetailLoading: false,
        //studentReportList: action.payload.studentReportList,
        actDetailReportData: action.payload.studentReportList,
        totalStudent: action.payload.totalStudent,
        testStudent: action.payload.testStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_ACT_DETAILED_LIST_FAILURE:
      return {
        ...state,
        isActDetailLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST:
      return {
        ...state,
        isCourseOverviewLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_SUCCESS:
      return {
        ...state,
        isCourseOverviewLoading: false,
        studentCourseReportData: action.payload.studentCourseReportData,
        totalStudent: action.payload.totalStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_COURSE_PROGRESS_OVERVIEW_LIST_FAILURE:
      return {
        ...state,
        isCourseOverviewLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST:
      return {
        ...state,
        isActScoreOverviewLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
      return {
        ...state,
        isActScoreOverviewLoading: false,
        studentActScoreReportData: action.payload.studentActScoreReportData,
        totalStudent: action.payload.totalStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_ACT_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
      return {
        ...state,
        isActScoreOverviewLoading: false,
        error: action.error,
      };

    case ReportActionTypes.REQUEST_STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST:
      return {
        ...state,
        isQuizScoreOverviewLoading: true,
        error: "",
      };
    case ReportActionTypes.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_SUCCESS:
      return {
        ...state,
        isQuizScoreOverviewLoading: false,
        studentQuizScoreReportData: action.payload.studentQuizScoreReportData,
        totalStudent: action.payload.totalStudent,
        error: "",
      };
    case ReportActionTypes.STUDENTS_QUIZ_SCORE_PROGRESS_OVERVIEW_LIST_FAILURE:
      return {
        ...state,
        isQuizScoreOverviewLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
