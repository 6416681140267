import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const addAssignment = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_ASSIGNMENT.service,
    ApiRoutes.ADD_ASSIGNMENT.url,
    ApiRoutes.ADD_ASSIGNMENT.method,
    ApiRoutes.ADD_ASSIGNMENT.authenticate,
    undefined,
    payload
  );
  return response;
};

const getAssignments = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_ASSIGNMENTS.service,
    ApiRoutes.GET_ASSIGNMENTS.url,
    ApiRoutes.GET_ASSIGNMENTS.method,
    ApiRoutes.GET_ASSIGNMENTS.authenticate,
    payload,
    undefined
  );
  return response;
};

const updateAssignment = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_ASSIGNMENT.service,
    ApiRoutes.UPDATE_ASSIGNMENT.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_ASSIGNMENT.method,
    ApiRoutes.UPDATE_ASSIGNMENT.authenticate,
    undefined,
    payload
  );
  return response;
};
const deleteAssignment = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.DELETE_ASSIGNMENTS.service,
    ApiRoutes.DELETE_ASSIGNMENTS.url.replace(":id", payload.id),
    ApiRoutes.DELETE_ASSIGNMENTS.method,
    ApiRoutes.DELETE_ASSIGNMENTS.authenticate,
    undefined,
    undefined
  );
  return response;
};

export const assignmentService = {
  addAssignment,
  getAssignments,
  updateAssignment,
  deleteAssignment,
};
