/** @format */

import { teacherService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const TeacherActionTypes = {
  TEACHER_LIST_REQUEST: "Teacher Listing Request!",
  TEACHER_LIST_SUCCESS: "Teacher Listed Successfully!",
  TEACHER_LIST_FAILED: "Teacher Listing Failed!",
  UNIT_TEACHER_LIST_REQUEST: "Unit Wise Teacher Listing Request!",
  UNIT_TEACHER_LIST_SUCCESS: "Unit Wise Teacher Listed Successfully!",
  UNIT_TEACHER_LIST_FAILED: "Unit Wise Teacher Listing Failed!",
  TEACHER_VIEW_REQUEST: "Teacher View Request!",
  TEACHER_VIEW_SUCCESS: "Teacher View Successfully!",
  TEACHER_VIEW_FAILED: "Teacher View Failed!",
  TEACHER_STATUS_UPDATE_REQUEST: "Teacher Status Update Request!",
  TEACHER_STATUS_UPDATE_SUCCESS: "Teacher Status Update Successfully!",
  TEACHER_STATUS_UPDATE_FAILED: "Teacher Status Update Failed!",
  TEACHER_ADD_REQUEST: "Teacher Add Request!",
  TEACHER_ADD_SUCCESS: "Teacher Add Successfully!",
  TEACHER_ADD_FAILED: "Teacher Add Failed!",
  TEACHER_UPDATE_REQUEST: "Teacher Update Request!",
  TEACHER_UPDATE_SUCCESS: "Teacher Update Successfully!",
  TEACHER_UPDATE_FAILED: "Teacher Update Failed!",
  DELETE_TEACHER_SUCCESS: "Teacher Deleted Successfully!",

  TEACHER_IMPORT_REQUEST: "Teacher Import Request!",
  TEACHER_IMPORT_SUCCESS: "Teacher Import Successfully!",
  TEACHER_IMPORT_FAILED: "Teacher Import Failed!",

  TEACHER_MOVE_REQUEST: "TEACHER_MOVE_REQUEST",
  TEACHER_MOVE_SUCCESS: "TEACHER_MOVE_SUCCESS",
  TEACHER_MOVE_FAILED: "TEACHER_MOVE_FAILED",

  RESET_IMPORT_ERROR: "RESET_IMPORT_ERROR",
};

const getTeachers = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await teacherService.getTeachers(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: TeacherActionTypes.TEACHER_LIST_SUCCESS,
      payload: {
        teachers: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
    if (payload.search !== '') {showError("Invalid search");}
    return { type: TeacherActionTypes.TEACHER_LIST_FAILED, error };
  }
};

const viewTeacher = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await teacherService.viewTeacher(
      payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_VIEW_REQUEST };
  }
  function success(data) {
    return {
      type: TeacherActionTypes.TEACHER_VIEW_SUCCESS,
      payload: { teacherData: data.data },
    };
  }
  function failure(error) {
    return { type: TeacherActionTypes.TEACHER_VIEW_FAILED, error };
  }
};

const updateTeacherStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await teacherService.updateTeacherStatus(
      payload
    );
    if (!isError) {
      /* if (payload.query) {
        dispatch(getTeachers({ ...payload.query }));
      } else {
        dispatch(viewTeacher({ id: payload.id }));
      } */

      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_STATUS_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: TeacherActionTypes.TEACHER_STATUS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: TeacherActionTypes.TEACHER_STATUS_UPDATE_FAILED, error };
  }
};

const addTeacher = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await teacherService.addTeacher(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_ADD_REQUEST };
  }

  function success() {
    return {
      type: TeacherActionTypes.TEACHER_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: TeacherActionTypes.TEACHER_ADD_FAILED, error };
  }
};

const updateTeacher = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await teacherService.updateTeacher(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: TeacherActionTypes.TEACHER_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: TeacherActionTypes.TEACHER_UPDATE_FAILED, error };
  }
};

const deleteTeacher = (payload) => {
  return async (dispatch) => {
    const { isError, messages } = await teacherService.deleteTeacher(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };

  function success() {
    return {
      type: TeacherActionTypes.DELETE_TEACHER_SUCCESS,
    };
  }
};

const getUnitTeachers = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await teacherService.unitTeachers(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: TeacherActionTypes.UNIT_TEACHER_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: TeacherActionTypes.UNIT_TEACHER_LIST_SUCCESS,
      payload: {
        unitWiseTeachers: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: TeacherActionTypes.UNIT_TEACHER_LIST_FAILED, error };
  }
};

const importTeachersData = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await teacherService.importTeachersData(
      payload
    );
    if (!isError) {
      dispatch(success(data.errorData, messages[0]));
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_IMPORT_REQUEST };
  }

  function success(errorData, isImportMessage) {
    return {
      type: TeacherActionTypes.TEACHER_IMPORT_SUCCESS,
      errorData: errorData,
      isImportMessage,
    };
  }

  function failure(error) {
    return { type: TeacherActionTypes.TEACHER_IMPORT_FAILED, error };
  }
};

const moveForwardTeacher = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await teacherService.moveForwardTeacher(
      payload
    );

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: TeacherActionTypes.TEACHER_MOVE_REQUEST };
  }

  function success() {
    return {
      type: TeacherActionTypes.TEACHER_MOVE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: TeacherActionTypes.TEACHER_MOVE_FAILED, error };
  }
};

export const teacherActions = {
  getTeachers,
  viewTeacher,
  updateTeacherStatus,
  addTeacher,
  updateTeacher,
  deleteTeacher,
  getUnitTeachers,
  importTeachersData,
  moveForwardTeacher,
};
