export const AuthInitialStates = {
  isLoading: false,
  isUserDetailsLoading: true,
  loggedIn: false,
  user: null,
  isAuthenticated: false,
  userDetails: [],
  access: {},
  verifyToken: false,
  verifyTokenLoading: true,
  verifiedData: [],
  planDetails: [],
  subscriptionYears: [],
};

export const ResetPasswordInitialStates = {
  isLoading: false,
  isPasswordSet: false,
};

export const ForgotPasswordInitialStates = {
  isLoading: false,
};
