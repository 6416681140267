/** @format */
import * as Yup from "yup";
import {
  FieldsMinLengths,
  FieldsMaxLengths,
  message,
  alphabeticCharacterRegex,
} from "../common";

export const StudentSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  firstName: Yup.string()
    .trim()
    .required("First name must be at least 1 letter")
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    )
    .min(
      FieldsMinLengths.firstName,
      message.MinLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMinLengths.firstName
      )
    )
    .max(
      FieldsMaxLengths.firstName,
      message.MaxLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMaxLengths.firstName
      )
    ),

  lastName: Yup.string()
    .trim()
    .required("Last name must be at least 1 letter")
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    )
    .min(
      FieldsMinLengths.lastName,
      message.MinLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMinLengths.lastName
      )
    )
    .max(
      FieldsMaxLengths.lastName,
      message.MaxLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMaxLengths.lastName
      )
    ),
  userName: Yup.string()
    .trim()
    .when("id", (id) => {
      if (id)
        return Yup.string().required(
          message.Required.replace(":item", "Username")
        );
    }),
  gender: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Gender")),
  ethnicity: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Race/Ethnicity")),
  grade: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Grade level")),
  homePhoneNumber: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Home phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Home phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),

  cellPhoneNumber: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Cell phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Cell phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  includeEconomically: Yup.string().nullable(true),
  // economicallyDisadvantaged: Yup.string()
  //   .trim()
  //   .nullable(true)
  //   .when("includeEconomically", (includeEconomically) => {
  //     if (includeEconomically === "yes")
  //       return Yup.string()
  //         .nullable(true)
  //         .required(message.Required.replace(":item", "This"));
  //   }),
  // learningDisability: Yup.string()
  //   .trim()
  //   .nullable(true)
  //   .when("includeEconomically", (includeEconomically) => {
  //     if (includeEconomically === "yes")
  //       return Yup.string()
  //         .nullable(true)
  //         .required(message.Required.replace(":item", "This"));
  //   }),
  // englishLearner: Yup.string()
  //   .trim()
  //   .nullable(true)
  //   .when("includeEconomically", (includeEconomically) => {
  //     if (includeEconomically === "yes")
  //       return Yup.string()
  //         .nullable(true)
  //         .required(message.Required.replace(":item", "This"));
  //   }),
});

export const StudentImportSchema = Yup.object().shape({
  fileObject: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "File")),
});
