
import { SubscriptionYearInitialStates } from "../states";
import { SubscrpitionYearActionTypes } from "../actions";

export const SubscriptionYearReducers = (state = SubscriptionYearInitialStates, action) => {
  switch (action.type) {
    case SubscrpitionYearActionTypes.SUBSCRIPTION_YEAR_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case SubscrpitionYearActionTypes.SUBSCRIPTION_YEAR_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Data: action.payload.Data,
        error: "",
      };
    case SubscrpitionYearActionTypes.SUBSCRIPTION_YEAR_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
