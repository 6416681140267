import Swal from "sweetalert2";

/**
 *
 * @param {*} message
 */
export const showSuccess = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    width: 350,
    showCloseButton: true,
    customClass: {
      popup: "custom-toaster",
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

/**
 *
 * @param {*} message
 */
export const showError = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    width: 350,
    showCloseButton: true,
    customClass: {
      popup: "custom-toaster",
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};
