/** @format */
import * as Yup from "yup";
import {
  FieldsMinLengths,
  FieldsMaxLengths,
  message,
  alphabeticCharacterRegex,
} from "../common";

export const TeacherSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  firstName: Yup.string()
    .trim()
    .required("First name must be at least 1 letter")
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    )
    .min(
      FieldsMinLengths.firstName,
      message.MinLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMinLengths.firstName
      )
    )
    .max(
      FieldsMaxLengths.firstName,
      message.MaxLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMaxLengths.firstName
      )
    ),

  lastName: Yup.string()
    .trim()
    .required("Last name must be at least 1 letter")
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    )
    .min(
      FieldsMinLengths.lastName,
      message.MinLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMinLengths.lastName
      )
    )
    .max(
      FieldsMaxLengths.lastName,
      message.MaxLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMaxLengths.lastName
      )
    ),
  // department: Yup.mixed().required(
  //   message.Required.replace(":item", "Department")
  // ),

  subjects: Yup.mixed().required(message.Required.replace(":item", "Subject")),

  phoneNumber: Yup.string()
    .trim()
    .notRequired()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),

  faxNumber: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax number")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),
});

export const TeacherImportSchema = Yup.object().shape({
  fileObject: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "File")),
});
