import * as Yup from "yup";
import {
  FieldsMaxLengths,
  message,
  alphabeticCharacterRegex,
  // alphaNumericCharacterRegex,
  FieldsMinLengths,
} from "../common";
export const SchoolSchema = Yup.object().shape({
  schoolName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "School name")),
  ncesSchoolCode: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "NCES School ID")),
  ncesDistrictID: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "NCES District ID"))
    // .matches(
    //   alphaNumericCharacterRegex,
    //   message.InvalidNameNumber.replace(":item", "NCES District ID")
    // ),
    .max(
      FieldsMaxLengths.ncesDistrictID,
      message.MaxLengthError.replace(":item", "NCES District ID").replace(
        ":length",
        FieldsMaxLengths.ncesDistrictID
      )
    ),
  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  userName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Username")),
  phoneNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Phone number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  faxNumber: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),
  // Super Admin
  superAdminFirstName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "First name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    )
    .min(
      FieldsMinLengths.firstName,
      message.MinLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMinLengths.firstName
      )
    )
    .max(
      FieldsMaxLengths.firstName,
      message.MaxLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMaxLengths.firstName
      )
    ),
  superAdminLastName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Last name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    )
    .min(
      FieldsMinLengths.lastName,
      message.MinLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMinLengths.lastName
      )
    )
    .max(
      FieldsMaxLengths.lastName,
      message.MaxLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMaxLengths.lastName
      )
    ),

  superAdminJobTitle: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    // .test(
    //   "jobTitle",
    //   message.InvalidNameNumber.replace(":item", "Job Title"),
    //   function (value) {
    //     if (!!value) {
    //       const schema = Yup.string().matches(alphaNumericCharacterRegex);
    //       return schema.isValidSync(value);
    //     }
    //     return true;
    //   }
    // )
    .test(
      "jobTitleLength",
      message.MaxLengthError.replace(":item", "Job title").replace(
        ":length",
        FieldsMaxLengths.jobTitle
      ),
      function (value) {
        if (!!value) {
          const schema = Yup.string().max(FieldsMaxLengths.jobTitle);
          return schema.isValidSync(value);
        }
        return true;
      }
    ),
  superAdminEmail: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  superAdminPhoneNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Phone number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  superAdminFax: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),

  // Super Admin
  principalFirstName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "First name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    )
    .min(
      FieldsMinLengths.firstName,
      message.MinLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMinLengths.firstName
      )
    )
    .max(
      FieldsMaxLengths.firstName,
      message.MaxLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMaxLengths.firstName
      )
    ),
  principalLastName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Last name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    )
    .min(
      FieldsMinLengths.lastName,
      message.MinLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMinLengths.lastName
      )
    )
    .max(
      FieldsMaxLengths.lastName,
      message.MaxLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMaxLengths.lastName
      )
    ),
  principalEmail: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),
  principalPhoneNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Phone number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  principalFax: Yup.string()
    .trim()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Fax"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Fax")
        .replace(":min", FieldsMinLengths.fax)
        .replace(":max", FieldsMaxLengths.fax),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.fax &&
          value.length <= FieldsMaxLengths.fax)
    ),

  physicalAddress: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Physical address")),
  physicalState: Yup.mixed().required(
    message.Required.replace(":item", "Physical state")
  ),
  physicalCity: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Physical city")),
  // physicalCity: Yup.mixed().required(
  //   message.Required.replace(":item", "Physical city")
  // ),
  physicalCounty: Yup.mixed().required(
    message.Required.replace(":item", "Physical county")
  ),
  physicalZip: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Physical zip code")),

  mailingAddress: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Mailing address")),
  mailingState: Yup.mixed().required(
    message.Required.replace(":item", "Mailing state")
  ),

  mailingCity: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Mailing city")),
  mailingCounty: Yup.mixed().required(
    message.Required.replace(":item", "Mailing county")
  ),
  mailingZip: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Mailing zip code")),
});
