import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import classnames from "classnames";

import banner from "../../../assets/images/login-banner.png";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { ForgotPasswordSchema } from "../../../validators";
import { getValidationErrors } from "../../../helpers";
import { authActions } from "../../../redux/actions";
import * as Styles from "./index.module.scss";

const initialStates = {
  email: "",
};

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState(initialStates);
  const [errors, setErrors] = useState(initialStates);

  const { isLoading = false } = useSelector(
    (state) => state.forgotPasswordReducer
  );

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const { email } = inputs;
      const data = {
        email,
      };

      ForgotPasswordSchema.validateSync(data, {
        abortEarly: false,
      });

      dispatch(authActions.forgotPassword(data));
    } catch (error) {
      setErrors(getValidationErrors(error));
    }
  };

  const goToLogin = () => {
    props.history.push("/login");
  };
  return (
    <div
      className={classnames(
        Styles["auth-wrapper"],
        "app flex-row align-items-center"
      )}
    >
      <div className={classnames(Styles["auth-content"])}>
        <Row className="justify-content-center align-items-stretch no-gutters h-auto w-100">
          <Col md={5} lg={6} className="d-none d-md-flex">
            <div className={Styles["right-section"]}>
              <div className={Styles["banner-text"]}>
                <h2>
                  Welcome to <span>UpScore</span>
                </h2>
                <p>
                  College and career readiness are <br />
                  just a click away.
                </p>
              </div>
              <div className={Styles["banner-img"]}>
                <img src={banner} alt="banner" />
              </div>
            </div>
          </Col>
          <Col md={7} lg={6}>
            <ForgotPasswordForm
              inputs={inputs}
              errors={errors}
              isLoading={isLoading}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              goToLogin={goToLogin}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
