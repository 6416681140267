export const AssignmentInitialStates = {
  isLoading: false,
  isDeleted:false,
  isUpdated: false,
  isSubmitting: false,
  error: false,
  assignments: [],
  assignmentData: {},
  totalRecords: 0,
};
