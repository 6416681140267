import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const addSupportRequest = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SUPPORT_REQUEST.service,
      ApiRoutes.ADD_SUPPORT_REQUEST.url,
      ApiRoutes.ADD_SUPPORT_REQUEST.method,
      ApiRoutes.ADD_SUPPORT_REQUEST.authenticate,
      undefined,
      payload
    );
    return response;
  };

const updateSupportRequestStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_SUPPORT_REQUEST_STATUS.service,
    ApiRoutes.UPDATE_SUPPORT_REQUEST_STATUS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_SUPPORT_REQUEST_STATUS.method,
    ApiRoutes.UPDATE_SUPPORT_REQUEST_STATUS.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateSupportRequest = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_SUPPORT_REQUEST.service,
    ApiRoutes.UPDATE_SUPPORT_REQUEST.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_SUPPORT_REQUEST.method,
    ApiRoutes.UPDATE_SUPPORT_REQUEST.authenticate,
    undefined,
    payload
  );
  return response;
};

const getSupportRequests = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUPPORT_REQUESTS.service,
      ApiRoutes.GET_SUPPORT_REQUESTS.url,
      ApiRoutes.GET_SUPPORT_REQUESTS.method,
      ApiRoutes.GET_SUPPORT_REQUESTS.authenticate,
      payload,
      undefined
    );
    return response;
  };
  
  const viewSupportRequest = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_SUPPORT_REQUEST.service,
      ApiRoutes.VIEW_SUPPORT_REQUEST.url.replace(":id", payload.id),
      ApiRoutes.VIEW_SUPPORT_REQUEST.method,
      ApiRoutes.VIEW_SUPPORT_REQUEST.authenticate,
      undefined,
      payload
    );
    return response;
  };

export const supportRequestService = {
  addSupportRequest,
  updateSupportRequest,
  updateSupportRequestStatus,
  getSupportRequests,
  viewSupportRequest,
};
