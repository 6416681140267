import { showError } from "../../components/MessageHandler";
import { dashboardRequestService } from "../services";

export const DashboardActionTypes = {
  //user count
  ALL_USER_COUNT_REQUEST: "All Users Count Request!",
  ALL_USER_COUNT_SUCCESS: "All Users Count fetch Successfully!",
  ALL_USER_COUNT_FAILED: "Failed to get All Users Count!",

  // student count
  STUDENT_COUNT_REQUEST: "Student Count Request!",
  STUDENT_COUNT_SUCCESS: "Student Count fetch Successfully!",
  STUDENT_COUNT_FAILED: "Failed to get Student Count!",

  //seat count
  SEAT_COUNT_REQUEST: "Seat Count Request!",
  SEAT_COUNT_SUCCESS: "Seat Count fetch Successfully!",
  SEAT_COUNT_FAILED: "Failed to get Seat Count!",

  //class count
  CLASS_COUNT_REQUEST: "Class Count Request!",
  CLASS_COUNT_SUCCESS: "Class Count fetch Successfully!",
  CLASS_COUNT_FAILED: "Failed to get Class Count!",

  //group count
  GROUP_COUNT_REQUEST: "Group Count Request!",
  GROUP_COUNT_SUCCESS: "Group Count fetch Successfully!",
  GROUP_COUNT_FAILED: "Failed to get Group Count!",

  // support count
  SUPPORT_COUNT_REQUEST: "Support Count Request!",
  SUPPORT_COUNT_SUCCESS: "Support Count fetch Successfully!",
  SUPPORT_COUNT_FAILED: "Failed to get Support Count!",

  // Teacher count
  TEACHER_COUNT_REQUEST: "Teacher Count Request!",
  TEACHER_COUNT_SUCCESS: "Teacher Count fetch Successfully!",
  TEACHER_COUNT_FAILED: "Failed to get Teacher Count!",

  // essay count
  ESSAY_COUNT_REQUEST: "Essay Count Request!",
  ESSAY_COUNT_SUCCESS: "Essay Count fetch Successfully!",
  ESSAY_COUNT_FAILED: "Failed to get Essay Count!",

  // Teacher Student count
  TEACHER_STUDENT_COUNT_REQUEST: "Teacher Student Count Request!",
  TEACHER_STUDENT_COUNT_SUCCESS: "Teacher Student Count fetch Successfully!",
  TEACHER_STUDENT_COUNT_FAILED: "Failed to get Teacher Student Count!",

  // Teacher Assignment count
  TEACHER_ASSIGNMENT_COUNT_REQUEST: "Teacher Assignment Count Request!",
  TEACHER_ASSIGNMENT_COUNT_SUCCESS:
    "Teacher Assignment Count fetch Successfully!",
  TEACHER_ASSIGNMENT_COUNT_FAILED: "Failed to get Teacher Assignment Count!",

  // Sidebar toggle
  SET_SIDEBAR_SHOW: "SET_SIDEBAR_SHOW",
};

// all user count
const getAllUserCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getAllUserCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.ALL_USER_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.ALL_USER_COUNT_SUCCESS,
      payload: {
        allUserCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.ALL_USER_COUNT_FAILED, error };
  }
};

// student count
const getStudentCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getStudentCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.STUDENT_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.STUDENT_COUNT_SUCCESS,
      payload: {
        studentCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.STUDENT_COUNT_FAILED, error };
  }
};

// seat count
const getSeatCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getSeatCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.SEAT_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.SEAT_COUNT_SUCCESS,
      payload: {
        seatCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.SEAT_COUNT_FAILED, error };
  }
};

// class count
const getClassCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getClassCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.CLASS_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.CLASS_COUNT_SUCCESS,
      payload: {
        classCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.CLASS_COUNT_FAILED, error };
  }
};

// group count
const getGroupCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getGroupCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.GROUP_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.GROUP_COUNT_SUCCESS,
      payload: {
        groupCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.GROUP_COUNT_FAILED, error };
  }
};

// support count

const getSupportCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getSupportCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.SUPPORT_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.SUPPORT_COUNT_SUCCESS,
      payload: {
        supportCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.SUPPORT_COUNT_FAILED, error };
  }
};

// teacher count
const getTeacherCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getTeacherCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.TEACHER_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.TEACHER_COUNT_SUCCESS,
      payload: {
        teacherCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.TEACHER_COUNT_FAILED, error };
  }
};

// essay count
const getEssayCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getEssayCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.ESSAY_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.ESSAY_COUNT_SUCCESS,
      payload: {
        essayCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.ESSAY_COUNT_FAILED, error };
  }
};

// Teacher Student count
const getTeacherStudentCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getTeacherStudentCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.TEACHER_STUDENT_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.TEACHER_STUDENT_COUNT_SUCCESS,
      payload: {
        teacherStudentCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: DashboardActionTypes.TEACHER_STUDENT_COUNT_FAILED, error };
  }
};

// Teacher Assignment  count
const getTeacherAssignmentCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await dashboardRequestService.getTeacherAssignmentCount(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: DashboardActionTypes.TEACHER_ASSIGNMENT_COUNT_REQUEST };
  }

  function success(responseData) {
    return {
      type: DashboardActionTypes.TEACHER_ASSIGNMENT_COUNT_SUCCESS,
      payload: {
        teacherAssignmentCount: responseData.data,
      },
    };
  }

  function failure(error) {
    return {
      type: DashboardActionTypes.TEACHER_ASSIGNMENT_COUNT_FAILED,
      error,
    };
  }
};

export const dashboarActions = {
  getAllUserCount,
  getStudentCount,
  getSeatCount,
  getClassCount,
  getGroupCount,
  getSupportCount,
  getTeacherCount,
  getTeacherAssignmentCount,
  getTeacherStudentCount,
  getEssayCount,
};
