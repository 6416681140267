/** @format */

import { seatService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const SeatActionTypes = {
  SEAT_LIST_REQUEST: "SEAT_LIST_REQUEST",
  SEAT_LIST_SUCCESS: "SEAT_LIST_SUCCESS",
  SEAT_LIST_FAILED: "SEAT_LIST_FAILED",

  SEAT_UPDATE_REQUEST: "SEAT_UPDATE_REQUEST",
  SEAT_UPDATE_SUCCESS: "SEAT_UPDATE_SUCCESS",
  SEAT_UPDATE_FAILED: "SEAT_UPDATE_FAILED",

  REMOVE_ACCESS_REQUEST: "REMOVE_ACCESS_REQUEST",
  REMOVE_ACCESS_SUCCESS: "REMOVE_ACCESS_SUCCESS",
  REMOVE_ACCESS_FAILED: "REMOVE_ACCESS_FAILED",
};

const getSeats = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await seatService.getSeats(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: SeatActionTypes.SEAT_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: SeatActionTypes.SEAT_LIST_SUCCESS,
      payload: {
        seats: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
      if (payload.search !== '') {showError("Invalid search");}
    return { type: SeatActionTypes.SEAT_LIST_FAILED, error };
  }
};

const updateSeat = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await seatService.updateSeat(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SeatActionTypes.SEAT_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: SeatActionTypes.SEAT_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: SeatActionTypes.SEAT_UPDATE_FAILED, error };
  }
};

const removeAccess = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await seatService.removeAccess(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SeatActionTypes.REMOVE_ACCESS_REQUEST };
  }

  function success() {
    return {
      type: SeatActionTypes.REMOVE_ACCESS_SUCCESS,
    };
  }

  function failure(error) {
    return { type: SeatActionTypes.REMOVE_ACCESS_FAILED, error };
  }
};

export const seatActions = {
  getSeats,
  updateSeat,
  removeAccess,
};
