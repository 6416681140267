/** @format */
import * as Yup from "yup";
import { message, passwordRegex, passwordSpaceRegex } from "../common";

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "New password"))
    .min(8, message.minLengthPassword)
    .max(
      18,
      message.MaxLengthError.replace(":item", "New password").replace(
        ":length",
        18
      )
    )
    .matches(passwordSpaceRegex, message.SpaceError)
    .matches(passwordRegex, message.InvalidPassword),
  confirmPassword: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Confirm password"))
    .oneOf([Yup.ref("password"), null], message.passwordNotMatch),
});
