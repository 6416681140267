/** @format */

import { AssignmentInitialStates } from "../states";
import { AssignmentActionTypes } from "../actions";

export const AssignmentReducer = (state = AssignmentInitialStates, action) => {
  switch (action.type) {
    case AssignmentActionTypes.ASSIGNMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        error: "",
      };
    case AssignmentActionTypes.ASSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignments: action.payload.assignments,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case AssignmentActionTypes.ASSIGNMENT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case AssignmentActionTypes.ASSIGNMENT_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        isLoading: true,
        error: "",
      };
    case AssignmentActionTypes.ASSIGNMENT_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case AssignmentActionTypes.ASSIGNMENT_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        isLoading: false,
        error: action.error,
      };
    case AssignmentActionTypes.ASSIGNMENT_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case AssignmentActionTypes.ASSIGNMENT_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case AssignmentActionTypes.ASSIGNMENT_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };

    case AssignmentActionTypes.ASSIGNMENT_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    default:
      return state;
  }
};
