/** @format */
import * as Yup from "yup";
import { message } from "../common";

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email or username")),
  // .email(message.InvalidEmail),
});
