import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getSubscriptionYear = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION_YEAR.service,
      ApiRoutes.GET_SUBSCRIPTION_YEAR.url,
      ApiRoutes.GET_SUBSCRIPTION_YEAR.method,
      ApiRoutes.GET_SUBSCRIPTION_YEAR.authenticate,
      payload,
      undefined
      );
  return response;
};

export const SubscriptionYearService = {
    getSubscriptionYear,
};
