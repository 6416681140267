export const SupportRequestInitialStates = {
    isLoading: false,
    isAdded: false,
    isUpdated: false,
    isDeleted: false,
    isSubmitting: false,
    error: false,
    supportRequests: [],
    supportRequestData: {},
    totalRecords: 0,
  };