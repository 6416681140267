import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getNotifications = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_NOTIFICATION.service,
    ApiRoutes.GET_NOTIFICATION.url,
    ApiRoutes.GET_NOTIFICATION.method,
    ApiRoutes.GET_NOTIFICATION.authenticate,
    undefined,
    undefined
  );
  return response;
};

const updateNotificationStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_NOTIFICATION_STATUS.service,
    ApiRoutes.UPDATE_NOTIFICATION_STATUS.url,
    ApiRoutes.UPDATE_NOTIFICATION_STATUS.method,
    ApiRoutes.UPDATE_NOTIFICATION_STATUS.authenticate,
    payload,
    undefined
  );
  return response;
};

const updateNotificationCount = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_NOTIFICATION_COUNT.service,
    ApiRoutes.UPDATE_NOTIFICATION_COUNT.url,
    ApiRoutes.UPDATE_NOTIFICATION_COUNT.method,
    ApiRoutes.UPDATE_NOTIFICATION_COUNT.authenticate,
    undefined,
    undefined
  );
  return response;
};

export const notificationService = {
  getNotifications,
  updateNotificationStatus,
  updateNotificationCount,
};
