export const DashboardInitialStates = {
  isLoading: true,
  isAllUserLoading: true,
  isStudentCountLoading: true,
  isSeatCountLoading: true,
  isClassCountLoading: true,
  isGroupCountLoading: true,
  isSupportCountLoading: true,

  isTeacherCountLoading: true,
  isEssayCountLoading: true,
  isTeacherStudentCountLoading: true,
  isAssignmentCountLoading: true,

  error: false,
  allUserCount: [],
  studentCount: [],
  seatCount: [],
  classCount: [],
  groupCount: [],
  supportCount: [],
  teacherCount: [],
  teacherStudentCount: [],
  teacherAssignmentCount: [],
  essayCount: [],
  sidebarShow: "responsive",
};
