/** @format */
import { combineReducers } from "redux";
import {
  AuthReducer,
  ForgotPasswordReducer,
  ResetPasswordReducer,
} from "./Auth";
import { TeacherReducer } from "./Teacher";
import { ModalInfoReducer } from "./Modal";
import { SubjectReducer } from "./Subject";
import { StudentReducer } from "./Student";
import { ClassReducer } from "./Class";
import { SupportRequestReducer } from "./SupportRequest";
import { GroupReducer } from "./Group";
import { SubadminReducer } from "./Subadmin";
import { OrderHistoryReducer } from "./OrderHistory";
import { SeatReducer } from "./Seat";
import { AssignmentReducer } from "./Assignment";
import { NotificationReducer } from "./Notification";
import { EssayReducer } from "./Essay";
import { DashboardReducer } from "./Dashboard";
import { LinkageReducer } from "./LinkageRequest";
import { ReportReducer } from "./Report";
import { SubscriptionYearReducers } from "./SubscriptionYear";

export const RootReducer = combineReducers({
  authReducer: AuthReducer,
  resetPasswordReducer: ResetPasswordReducer,
  forgotPasswordReducer: ForgotPasswordReducer,
  teacherReducer: TeacherReducer,
  modalInfoReducer: ModalInfoReducer,
  subjectReducer: SubjectReducer,
  studentReducer: StudentReducer,
  classReducer: ClassReducer,
  supportRequestReducer: SupportRequestReducer,
  groupReducer: GroupReducer,
  subadminReducer: SubadminReducer,
  orderHistoryReducer: OrderHistoryReducer,
  seatReducer: SeatReducer,
  assignmentReducer: AssignmentReducer,
  notificationReducer: NotificationReducer,
  essayReducer: EssayReducer,
  dashboardReducer: DashboardReducer,
  linkageReducer: LinkageReducer,
  reportReducer: ReportReducer,
  subscriptionYearReducers: SubscriptionYearReducers
});
