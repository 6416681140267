export const SubadminInitialStates = {
  isLoading: true,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
  isSubmitting: false,
  error: false,
  subadmins: [],
  subadminData: {},
  totalRecords: 0,
};
