import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getEssays = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_ESSAYS.service,
    ApiRoutes.GET_ESSAYS.url,
    ApiRoutes.GET_ESSAYS.method,
    ApiRoutes.GET_ESSAYS.authenticate,
    payload,
    undefined
  );
  return response;
};

const viewEssay = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VIEW_ESSAY.service,
    ApiRoutes.VIEW_ESSAY.url.replace(":id", payload.id),
    ApiRoutes.VIEW_ESSAY.method,
    ApiRoutes.VIEW_ESSAY.authenticate,
    payload,
    undefined
  );
  return response;
};

const addEssay = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_ESSAY.service,
    ApiRoutes.ADD_ESSAY.url,
    ApiRoutes.ADD_ESSAY.method,
    ApiRoutes.ADD_ESSAY.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateEssay = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_ESSAY.service,
    ApiRoutes.UPDATE_ESSAY.url.replace(":id", payload._id),
    ApiRoutes.UPDATE_ESSAY.method,
    ApiRoutes.UPDATE_ESSAY.authenticate,
    undefined,
    payload
  );
  return response;
};

export const essayService = {
  getEssays,
  viewEssay,
  addEssay,
  updateEssay,
};
