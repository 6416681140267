/** @format */
import * as Yup from "yup";
import { FieldsMinLengths, message } from "../common";

export const AssignmentSchema = Yup.object().shape({
  assignmentName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Assignment name"))
    .min(
      FieldsMinLengths.assignmentName,
      message.MinLengthError.replace(":item", "Assignment name").replace(
        ":length",
        FieldsMinLengths.assignmentName
      )
    ),
  studentID: Yup.array()

    // .of(Yup.string())
    .required(message.Required.replace(":item", "student"))
    .min(1, "At least one student must be selected")
    .test("array-length", "At least one student must be selected", (value) => {
      if (value) {
        const schema = Yup.array().of(Yup.string());
        //!value.includes(null);
        // value.of(Yup.string())
        return schema.isValidSync(value);
      }
      return true;
    }),
  lessonID: Yup.array()
    .required(message.Required.replace(":item", "lesson"))
    .min(1, "At least one lesson must be selected")
    .test("array-length", "At least one lesson must be selected", (value) => {
      if (value) {
        const schema = Yup.array().of(Yup.string());
        return schema.isValidSync(value);
      }
      return true;
    }),
  dueDate: Yup.mixed().required(message.Required.replace(":item", "Due date")),
  classRoomID: Yup.mixed().required(message.Required.replace(":item", "Class")),
});
