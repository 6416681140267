import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getStudents = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENTS.service,
    ApiRoutes.GET_STUDENTS.url,
    ApiRoutes.GET_STUDENTS.method,
    ApiRoutes.GET_STUDENTS.authenticate,
    payload,
    undefined
  );
  return response;
};

const getAllStudents = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_ALL_STUDENTS.service,
    ApiRoutes.GET_ALL_STUDENTS.url,
    ApiRoutes.GET_ALL_STUDENTS.method,
    ApiRoutes.GET_ALL_STUDENTS.authenticate,
    payload,
    undefined
  );
  return response;
};

const viewStudent = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VIEW_STUDENT.service,
    ApiRoutes.VIEW_STUDENT.url.replace(":id", payload.id),
    ApiRoutes.VIEW_STUDENT.method,
    ApiRoutes.VIEW_STUDENT.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateStudentStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_STUDENT_STATUS.service,
    ApiRoutes.UPDATE_STUDENT_STATUS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_STUDENT_STATUS.method,
    ApiRoutes.UPDATE_STUDENT_STATUS.authenticate,
    undefined,
    payload
  );
  return response;
};

const addStudent = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_STUDENT.service,
    ApiRoutes.ADD_STUDENT.url,
    ApiRoutes.ADD_STUDENT.method,
    ApiRoutes.ADD_STUDENT.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateStudent = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_STUDENT.service,
    ApiRoutes.UPDATE_STUDENT.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_STUDENT.method,
    ApiRoutes.UPDATE_STUDENT.authenticate,
    undefined,
    payload
  );
  return response;
};

const deleteStudent = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.DELETE_STUDENT.service,
    ApiRoutes.DELETE_STUDENT.url.replace(":id", payload.id),
    ApiRoutes.DELETE_STUDENT.method,
    ApiRoutes.DELETE_STUDENT.authenticate,
    undefined,
    undefined
  );
  return response;
};

const importStudentsData = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.IMPORT_STUDENT.service,
    ApiRoutes.IMPORT_STUDENT.url,
    ApiRoutes.IMPORT_STUDENT.method,
    ApiRoutes.IMPORT_STUDENT.authenticate,
    undefined,
    payload
  );
  return response;
};

const moveForwardStudent = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.MOVE_STUDENT.service,
    ApiRoutes.MOVE_STUDENT.url,
    ApiRoutes.MOVE_STUDENT.method,
    ApiRoutes.MOVE_STUDENT.authenticate,
    undefined,
    payload
  );
  return response;
};

export const studentService = {
  getStudents,
  getAllStudents,
  viewStudent,
  updateStudentStatus,
  addStudent,
  updateStudent,
  deleteStudent,
  importStudentsData,
  moveForwardStudent,
};
