import { SupportRequestInitialStates } from "../states";
import { SupportRequestActionTypes } from "../actions";

export const SupportRequestReducer = (state = SupportRequestInitialStates, action) => {
  switch (action.type) {
    case SupportRequestActionTypes.SUPPORT_REQUEST_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supportRequests: action.payload.supportRequests,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isDeleted: false,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supportRequestData: action.payload.supportRequestData,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case SupportRequestActionTypes.SUPPORT_REQUEST_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case SupportRequestActionTypes.DELETE_SUPPORT_REQUEST_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    default:
      return state;
  }
};
