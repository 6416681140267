/** @format */
import { ClassInitialStates } from "../states";
import { ClassActionTypes } from "../actions";

export const ClassReducer = (state = ClassInitialStates, action) => {
  switch (action.type) {
    case ClassActionTypes.CLASS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
      };
    case ClassActionTypes.CLASS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classes: action.payload.classes,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case ClassActionTypes.CLASS_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ClassActionTypes.CLASS_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isDeleted: false,
        error: "",
      };
    case ClassActionTypes.CLASS_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classData: action.payload.classData,
        error: "",
      };
    case ClassActionTypes.CLASS_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ClassActionTypes.CLASS_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case ClassActionTypes.CLASS_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case ClassActionTypes.CLASS_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ClassActionTypes.CLASS_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case ClassActionTypes.CLASS_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case ClassActionTypes.CLASS_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case ClassActionTypes.CLASS_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case ClassActionTypes.CLASS_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case ClassActionTypes.CLASS_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case ClassActionTypes.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case ClassActionTypes.FILL_CLASS_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ClassActionTypes.CLASS_MOVE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
      };
    case ClassActionTypes.CLASS_MOVE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: "",
      };
    case ClassActionTypes.CLASS_MOVE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    default:
      return state;
  }
};
