import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from "./config";
import configureStore from "./redux/store";
import "./scss/style.scss";
import ForgotPassword from "./views/Pages/ForgotPassword";
import FullPageLoader from "./containers/Loader/FullPageLoader";

const history = createBrowserHistory();
const store = configureStore(history);

const loading = (
  <div className="pt-3 text-center">
    {/* <div className="sk-spinner sk-spinner-pulse"></div> */}
    <FullPageLoader />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const ProxyLogin = React.lazy(() => import("./views/Pages/ProxyLogin"));
const ResetPassword = React.lazy(() => import("./views/Pages/ResetPassword"));
const Page404 = React.lazy(() => import("./views/Pages/Page404/Page404"));

/**
 *
 */
const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path={AppRoutes.LOGIN}
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              path={AppRoutes.ProxyLogin}
              name="Proxy Login Page"
              render={(props) => <ProxyLogin {...props} />}
            />
            <Route
              path={AppRoutes.RESET_PASSWORD}
              name="Reset Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              path={AppRoutes.FORGOT_PASSWORD}
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />

            <Route
              path={AppRoutes.HOME}
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
        <ToastContainer />
      </Router>
    </Provider>
  );
};

export default App;
