/** @format */

import { SeatInitialStates } from "../states";
import { SeatActionTypes } from "../actions";

export const SeatReducer = (state = SeatInitialStates, action) => {
  switch (action.type) {
    case SeatActionTypes.SEAT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case SeatActionTypes.SEAT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seats: action.payload.seats,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case SeatActionTypes.SEAT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SeatActionTypes.SEAT_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case SeatActionTypes.SEAT_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case SeatActionTypes.SEAT_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };

    case SeatActionTypes.REMOVE_ACCESS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case SeatActionTypes.REMOVE_ACCESS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case SeatActionTypes.REMOVE_ACCESS_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    default:
      return state;
  }
};
