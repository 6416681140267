/** @format */

import { studentService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const StudentActionTypes = {
  STUDENT_LIST_REQUEST: "Student Listing Request!",
  STUDENT_LIST_SUCCESS: "Student Listed Successfully!",
  STUDENT_LIST_FAILED: "Student Listing Failed!",

  ALL_STUDENT_LIST_REQUEST: "All Student Listing Request!",
  ALL_STUDENT_LIST_SUCCESS: "All Student Listed Successfully!",
  ALL_STUDENT_LIST_FAILED: "All Student Listing Failed!",

  STUDENT_VIEW_REQUEST: "Student View Request!",
  STUDENT_VIEW_SUCCESS: "Student View Successfully!",
  STUDENT_VIEW_FAILED: "Student View Failed!",

  STUDENT_STATUS_UPDATE_REQUEST: "Student Status Update Request!",
  STUDENT_STATUS_UPDATE_SUCCESS: "Student Status Update Successfully!",
  STUDENT_STATUS_UPDATE_FAILED: "Student Status Update Failed!",

  STUDENT_ADD_REQUEST: "Student Add Request!",
  STUDENT_ADD_SUCCESS: "Student Add Successfully!",
  STUDENT_ADD_FAILED: "Student Add Failed!",

  STUDENT_UPDATE_REQUEST: "Student Update Request!",
  STUDENT_UPDATE_SUCCESS: "Student Update Successfully!",
  STUDENT_UPDATE_FAILED: "Student Update Failed!",

  DELETE_STUDENT_SUCCESS: "Student Deleted Successfully!",

  STUDENT_IMPORT_REQUEST: "Student Import Request!",
  STUDENT_IMPORT_SUCCESS: "Student Import Successfully!",
  STUDENT_IMPORT_FAILED: "Student Import Failed!",

  STUDENT_MOVE_REQUEST: "STUDENT_MOVE_REQUEST",
  STUDENT_MOVE_SUCCESS: "STUDENT_MOVE_SUCCESS",
  STUDENT_MOVE_FAILED: "STUDENT_MOVE_FAILED",

  RESET_IMPORT_ERROR: "RESET_IMPORT_ERROR",
};

const getStudents = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await studentService.getStudents(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: StudentActionTypes.STUDENT_LIST_SUCCESS,
      payload: {
        students: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
    if (payload.search !== '') {showError("Invalid search");}
    return { type: StudentActionTypes.STUDENT_LIST_FAILED, error };
  }
};

const getAllStudents = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await studentService.getAllStudents(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: StudentActionTypes.ALL_STUDENT_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: StudentActionTypes.ALL_STUDENT_LIST_SUCCESS,
      payload: {
        allStudents: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: StudentActionTypes.ALL_STUDENT_LIST_FAILED, error };
  }
};

const viewStudent = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await studentService.viewStudent(
      payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_VIEW_REQUEST };
  }
  function success(data) {
    return {
      type: StudentActionTypes.STUDENT_VIEW_SUCCESS,
      payload: { studentData: data.data },
    };
  }
  function failure(error) {
    return { type: StudentActionTypes.STUDENT_VIEW_FAILED, error };
  }
};

const updateStudentStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await studentService.updateStudentStatus(
      payload
    );
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_STATUS_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: StudentActionTypes.STUDENT_STATUS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: StudentActionTypes.STUDENT_STATUS_UPDATE_FAILED, error };
  }
};

const addStudent = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await studentService.addStudent(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_ADD_REQUEST };
  }

  function success() {
    return {
      type: StudentActionTypes.STUDENT_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: StudentActionTypes.STUDENT_ADD_FAILED, error };
  }
};

const updateStudent = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await studentService.updateStudent(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: StudentActionTypes.STUDENT_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: StudentActionTypes.STUDENT_UPDATE_FAILED, error };
  }
};

const deleteStudent = (payload) => {
  return async (dispatch) => {
    const { isError, messages } = await studentService.deleteStudent(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };

  function success() {
    return {
      type: StudentActionTypes.DELETE_STUDENT_SUCCESS,
    };
  }
};

const importStudentsData = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await studentService.importStudentsData(
      payload
    );
    if (!isError) {
      dispatch(success(data.errorData, messages[0]));
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_IMPORT_REQUEST };
  }

  function success(errorData, isImportMessage) {
    return {
      type: StudentActionTypes.STUDENT_IMPORT_SUCCESS,
      errorData: errorData,
      isImportMessage,
    };
  }

  function failure(error) {
    return { type: StudentActionTypes.STUDENT_IMPORT_FAILED, error };
  }
};

const moveForwardStudent = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await studentService.moveForwardStudent(
      payload
    );

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: StudentActionTypes.STUDENT_MOVE_REQUEST };
  }

  function success() {
    return {
      type: StudentActionTypes.STUDENT_MOVE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: StudentActionTypes.STUDENT_MOVE_FAILED, error };
  }
};

export const studentActions = {
  getAllStudents,
  getStudents,
  viewStudent,
  updateStudentStatus,
  addStudent,
  updateStudent,
  deleteStudent,
  importStudentsData,
  moveForwardStudent,
};
