/* eslint-disable max-len */
/* eslint-disable comma-dangle */
import * as Yup from "yup";
import { message } from "../common";
import {
  alphabeticCharacterRegex,
  FieldsMinLengths,
  FieldsMaxLengths,
} from "../common";

export const SubadminSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First name must be at least 1 letter")
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "First name")
    )
    .min(
      FieldsMinLengths.firstName,
      message.MinLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMinLengths.firstName
      )
    )
    .max(
      FieldsMaxLengths.firstName,
      message.MaxLengthError.replace(":item", "First name").replace(
        ":length",
        FieldsMaxLengths.firstName
      )
    ),
  lastName: Yup.string()
    .trim()
    .required("Last name must be at least 1 letter")
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Last name")
    )
    .min(
      FieldsMinLengths.lastName,
      message.MinLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMinLengths.lastName
      )
    )
    .max(
      FieldsMaxLengths.lastName,
      message.MaxLengthError.replace(":item", "Last name").replace(
        ":length",
        FieldsMaxLengths.lastName
      )
    ),
  email: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Email address"))
    .email(message.InvalidEmail),

  phoneNumber: Yup.string()
    .notRequired()
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Phone number"),
      (value) => !value || (value && !isNaN(value))
    )
    .test(
      "num-length",
      message.NumberLengthError.replace(":item", "Phone number")
        .replace(":min", FieldsMinLengths.phoneNumber)
        .replace(":max", FieldsMaxLengths.phoneNumber),
      (value) =>
        !value ||
        (value &&
          value.length >= FieldsMinLengths.phoneNumber &&
          value.length <= FieldsMaxLengths.phoneNumber)
    ),
  // jobTitle: Yup.string()
  // .trim()
  // .required(message.Required.replace(":item", "Email Address"))
  // .email(message.InvalidEmail),
});

export default SubadminSchema;
