import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getClasses = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_CLASSES.service,
    ApiRoutes.GET_CLASSES.url,
    ApiRoutes.GET_CLASSES.method,
    ApiRoutes.GET_CLASSES.authenticate,
    payload,
    undefined
  );
  return response;
};

const viewClass = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VIEW_CLASS.service,
    ApiRoutes.VIEW_CLASS.url.replace(":id", payload.id),
    ApiRoutes.VIEW_CLASS.method,
    ApiRoutes.VIEW_CLASS.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateClassStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_CLASS_STATUS.service,
    ApiRoutes.UPDATE_CLASS_STATUS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_CLASS_STATUS.method,
    ApiRoutes.UPDATE_CLASS_STATUS.authenticate,
    undefined,
    payload
  );
  return response;
};

const addClass = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_CLASS.service,
    ApiRoutes.ADD_CLASS.url,
    ApiRoutes.ADD_CLASS.method,
    ApiRoutes.ADD_CLASS.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateClass = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_CLASS.service,
    ApiRoutes.UPDATE_CLASS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_CLASS.method,
    ApiRoutes.UPDATE_CLASS.authenticate,
    undefined,
    payload
  );
  return response;
};

const deleteClass = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.DELETE_CLASS.service,
    ApiRoutes.DELETE_CLASS.url.replace(":id", payload.id),
    ApiRoutes.DELETE_CLASS.method,
    ApiRoutes.DELETE_CLASS.authenticate,
    undefined,
    undefined
  );
  return response;
};

const moveForwardClass = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.MOVE_CLASS.service,
    ApiRoutes.MOVE_CLASS.url,
    ApiRoutes.MOVE_CLASS.method,
    ApiRoutes.MOVE_CLASS.authenticate,
    undefined,
    payload
  );
  return response;
};

export const classService = {
  getClasses,
  viewClass,
  updateClassStatus,
  addClass,
  updateClass,
  deleteClass,
  moveForwardClass,
};
