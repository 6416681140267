export const ModelActionTypes = {
  MODAL_TOGGLE_REQUEST: "Toggle modal Request!",
};

const modalToggleRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
  };
  function request(payload) {
    return { type: ModelActionTypes.MODAL_TOGGLE_REQUEST, payload };
  }
};

export const modalActions = {
  modalToggleRequest,
};
