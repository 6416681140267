import * as Yup from "yup";
import { message, passwordRegex, passwordSpaceRegex } from "../common";

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(
    message.Required.replace(":item", "Current password")
  ),
  newPassword: Yup.string()
    .required(message.Required.replace(":item", "New password"))
    //.min(8, message.minLengthPassword)
    .min(
      8,
      message.MinLengthError.replace(":item", "New password").replace(
        ":length",
        "8"
      )
    )
    .max(
      18,
      message.MaxLengthError.replace(":item", "New password").replace(
        ":length",
        "18"
      )
    )
    .matches(passwordSpaceRegex, message.SpaceError)
    .matches(passwordRegex, message.InvalidPassword),
  confirmPassword: Yup.string()
    .required(message.Required.replace(":item", "Confirm new password"))
    .oneOf([Yup.ref("newPassword"), null], message.passwordNotMatch),
});
