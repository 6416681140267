import { OrderHistoryInitialStates } from "../states";
import { OrderHistoryActionTypes } from "../actions";

export const OrderHistoryReducer = (
  state = OrderHistoryInitialStates,
  action
) => {
  switch (action.type) {
    case OrderHistoryActionTypes.ORDER_HISTORY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case OrderHistoryActionTypes.ORDER_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload.orders,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case OrderHistoryActionTypes.ORDER_HISTORY_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
