export const SubjectInitialStates = {
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  isSubmitting: false,
  error: false,
  subjects: [],
  subjectData: {},
  totalRecords: 0,
};
