import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getTeachers = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_TEACHERS.service,
    ApiRoutes.GET_TEACHERS.url,
    ApiRoutes.GET_TEACHERS.method,
    ApiRoutes.GET_TEACHERS.authenticate,
    payload,
    undefined
  );
  return response;
};

const viewTeacher = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VIEW_TEACHER.service,
    ApiRoutes.VIEW_TEACHER.url.replace(":id", payload.id),
    ApiRoutes.VIEW_TEACHER.method,
    ApiRoutes.VIEW_TEACHER.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateTeacherStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_TEACHER_STATUS.service,
    ApiRoutes.UPDATE_TEACHER_STATUS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_TEACHER_STATUS.method,
    ApiRoutes.UPDATE_TEACHER_STATUS.authenticate,
    undefined,
    payload
  );
  return response;
};

const addTeacher = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_TEACHER.service,
    ApiRoutes.ADD_TEACHER.url,
    ApiRoutes.ADD_TEACHER.method,
    ApiRoutes.ADD_TEACHER.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateTeacher = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_TEACHER.service,
    ApiRoutes.UPDATE_TEACHER.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_TEACHER.method,
    ApiRoutes.UPDATE_TEACHER.authenticate,
    undefined,
    payload
  );
  return response;
};

const deleteTeacher = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.DELETE_TEACHER.service,
    ApiRoutes.DELETE_TEACHER.url.replace(":id", payload.id),
    ApiRoutes.DELETE_TEACHER.method,
    ApiRoutes.DELETE_TEACHER.authenticate,
    undefined,
    undefined
  );
  return response;
};

const unitTeachers = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UNIT_WISE_TEACHER.service,
    ApiRoutes.UNIT_WISE_TEACHER.url,
    ApiRoutes.UNIT_WISE_TEACHER.method,
    ApiRoutes.UNIT_WISE_TEACHER.authenticate,
    payload,
    undefined
  );
  return response;
};

const importTeachersData = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.IMPORT_TEACHER.service,
    ApiRoutes.IMPORT_TEACHER.url,
    ApiRoutes.IMPORT_TEACHER.method,
    ApiRoutes.IMPORT_TEACHER.authenticate,
    undefined,
    payload
  );
  return response;
};

const moveForwardTeacher = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.MOVE_TEACHER.service,
    ApiRoutes.MOVE_TEACHER.url,
    ApiRoutes.MOVE_TEACHER.method,
    ApiRoutes.MOVE_TEACHER.authenticate,
    undefined,
    payload
  );
  return response;
};

export const teacherService = {
  getTeachers,
  viewTeacher,
  updateTeacherStatus,
  addTeacher,
  updateTeacher,
  deleteTeacher,
  unitTeachers,
  importTeachersData,
  moveForwardTeacher,
};
