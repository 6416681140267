/** @format */

import { authService } from "../services";
import { push } from "react-router-redux";
import { AppRoutes } from "../../config";
import { showError, showSuccess } from "../../components/MessageHandler";
import socketIOClient from "socket.io-client";
import { AppConfig } from "../../config/AppConfig";
import { modalActions } from "./Modal";
const { modalToggleRequest } = modalActions;

export const AuthActionTypes = {
  LOGIN_REQUEST: "Request user login!",
  LOGIN_FAILURE: "Login failed",
  LOGOUT_REQUEST: "Request logout",
  GET_USER_DETAILS: "Request to fetch user details",
  USER_DETAILS_SUCCESS: "User details fetched successfully!",
  USER_DETAILS_FAILED: "Failed to fetch User details!",
  UPDATE_PROFILE_REQUEST: "Request to fetch profile details",
  UPDATE_PROFILE_SUCCESS: "Profile updated successfully!",
  UPDATE_PROFILE_FAILED: "Failed to update profile details!",
  CHANGE_PASSWORD_REQUEST: "Request for password change",
  CHANGE_PASSWORD_SUCCESS: "Password updated successfully",
  CHANGE_PASSWORD_FAILURE: "Failed to update password",

  SET_PASSWORD_REQUEST: "Request for set password",
  SET_PASSWORD_SUCCESS: "Password set successfully",
  SET_PASSWORD_FAILURE: "Failed to set password",

  VERIFY_TOKEN_REQUEST: "Request for verify token",
  VERIFY_TOKEN_SUCCESS: "Token verified successfully",
  VERIFY_TOKEN_FAILURE: "Failed to verify token",

  REQUEST_FORGOT_PASSWORD: "Request forgot password",
  FORGOT_PASSWORD_SUCCESS: "Forgot password success",
  FORGOT_PASSWORD_FAILED: "Forgot password failed",
};

const login = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
    const { isError, messages, data } = await authService.login(payload);

    if (!isError) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("role", data.data.role);
      const socket = socketIOClient(AppConfig.SOCKET_ENDPOINT, {
        query: {
          token: data.data.token,
        },
        secure: true,
      });
      dispatch(success({ user: data.data }));
      dispatch(authActions.getUserDetails());
      dispatch(push(AppRoutes.DASHBOARD));
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      dispatch(failure());
    }
  };
  function request(user) {
    return { type: AuthActionTypes.LOGIN_REQUEST, user };
  }
  function success(payload) {
    return { type: AuthActionTypes.USER_DETAILS_SUCCESS, payload };
  }
  function failure(error) {
    return { type: AuthActionTypes.LOGIN_FAILURE, error };
  }
};

const getUserDetails = () => {
  return async (dispatch) => {
    dispatch(request()); //commented bcz its rendering component two times
    const { isError, messages, data } = await authService.getUserDetails();

    if (!isError) {
      dispatch(
        success({
          user: data.data,
          plan: data.plan,
          access: data.access,
          subscriptionYears: data.subscriptionYears,
        })
      );
    } else {
      showError(messages[0]);
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      dispatch(push(AppRoutes.LOGIN));
      dispatch(failure());
    }
  };
  function request() {
    return { type: AuthActionTypes.GET_USER_DETAILS };
  }
  function success(payload) {
    return { type: AuthActionTypes.USER_DETAILS_SUCCESS, payload };
  }
  function failure(error) {
    return { type: AuthActionTypes.USER_DETAILS_FAILED, error };
  }
};

const setPassword = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
    const { isError, messages } = await authService.setPassword(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure());
    } else {
      dispatch(success());
    }
  };
  function request(user) {
    return { type: AuthActionTypes.SET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: AuthActionTypes.SET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: AuthActionTypes.SET_PASSWORD_FAILURE, error };
  }
};

const logOut = () => {
  return async (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    dispatch(push(AppRoutes.LOGIN));
    dispatch(success());
  };
  function success() {
    return { type: AuthActionTypes.LOGOUT_REQUEST };
  }
};

const updateProfile = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
    const { isError, messages, data } = await authService.updateProfile(
      payload
    );
    if (!isError) {
      dispatch(success({ user: data.data }));
      showSuccess("Profile updated successfully");
      //dispatch(getUserDetails());
      dispatch(authActions.getUserDetails());
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: AuthActionTypes.UPDATE_PROFILE_REQUEST };
  }

  function success() {
    return {
      type: AuthActionTypes.UPDATE_PROFILE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: AuthActionTypes.UPDATE_PROFILE_FAILED, error };
  }
};

const changePassword = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
    const { isError, messages } = await authService.changePassword(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure());
    } else {
      dispatch(success());
      showSuccess(messages[0]);
      if (payload.role === "students") {
        dispatch(
          modalToggleRequest({ studentsChangePasswordModalOpen: false })
        );
      } else {
        setTimeout(function () {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          dispatch(push(AppRoutes.LOGIN));
        }, 2500);
      }
    }
  };
  function request(user) {
    return { type: AuthActionTypes.CHANGE_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: AuthActionTypes.CHANGE_PASSWORD_FAILURE, error };
  }
};

const verifyToken = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
    const { isError, data } = await authService.verifyToken(payload);
    if (isError) {
      // showError(messages[0]);
      dispatch(failure());
    } else {
      dispatch(success(data.data));
    }
  };
  function request() {
    return { type: AuthActionTypes.VERIFY_TOKEN_REQUEST };
  }
  function success(payload) {
    return { type: AuthActionTypes.VERIFY_TOKEN_SUCCESS, payload };
  }
  function failure() {
    return { type: AuthActionTypes.VERIFY_TOKEN_FAILURE };
  }
};

const forgotPassword = (payload) => {
  return async (dispatch) => {
    dispatch(request(payload));
    const { isError, messages } = await authService.forgotPassword(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure());
    } else {
      dispatch(success());
      dispatch(push(AppRoutes.LOGIN));
      showSuccess(messages[0]);
    }
  };
  function request() {
    return { type: AuthActionTypes.REQUEST_FORGOT_PASSWORD };
  }
  function success() {
    return { type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS };
  }
  function failure() {
    return { type: AuthActionTypes.FORGOT_PASSWORD_FAILED };
  }
};

export const authActions = {
  login,
  logOut,
  setPassword,
  getUserDetails,
  updateProfile,
  changePassword,
  verifyToken,
  forgotPassword,
};
