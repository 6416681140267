/** @format */

export const message = {
  Required: ":item is required",
  InvalidEmail: "Please enter valid email address",
  InvalidName: ":item must contain alphabets only",
  InvalidClassName:
    ":item contain alphabets, numeric digit and (- or / ) character only",
  InvalidPhone: "Phone Number must be in numeric digit only",
  ValidLink: "Please enter valid youtube video link",
  SpaceError: "Password cannot contain space",
  InvalidNameNumber: ":item must be alphanumeric only",
  InvalidImages: "Only images of type jpg, jpeg and png are allowed",
  InvalidItemCount: "Item count must be a whole number",
  MinItemCount: "Item count should be greater than 0",
  MaxLengthError: ":item length must not exceed :length characters",
  SubModuleRequired: "Please select area of access",
  InvalidWebsite: "Please enter valid website link",
  Alphanumeric: "Only alphanumeric characters are allowed",
  InvalidOldPassword: "Old password didn't match",
  UpdateSuccess: ":item updated successfully.",
  DeleteSuccess: ":item deleted successfully.",
  AddSuccess: ":item added successfully.",
  minLengthPassword: "Password should be at least 8 characters long",
  passwordNotMatch: "Password didn't match",
  InvalidPassword:
    "Password must contain at least one character and one number",
  InactiveMessage: "Do you want to inactivate this account?",
  ActiveMessage: "Do you want to activate this account?",
  MinLengthError: ":item must be at least :length characters",
  InvalidNumber: ":item must be in numeric digit only",
  NumberLengthError: ":item must be between :min to :max numbers",
  moveForward:
    "Do you want to move the selected :entity to the current subscription year?",
};
