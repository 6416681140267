import React from "react";
import { CSpinner } from "@coreui/react";
import { Button, Form, Input, FormGroup, Label, Row, Col } from "reactstrap";
import * as Icon from "react-feather";
import logo from "../../../assets/images/upscore.png";
import * as Styles from "./index.module.scss";
import classnames from "classnames";

export const ForgotPasswordForm = ({
  inputs,
  errors,
  handleChange,
  handleSubmit,
  goToLogin,
  isLoading = false,
}) => {
  const { email } = inputs;

  return (
    <Form onSubmit={handleSubmit} className={Styles["auth-card"]}>
      <div className="mb-4 text-center">
        <img src={logo} alt={"logo"} width="170px" />
      </div>
      <div className="mb-3 text-center">
        <h1 className={Styles["login-title"]}>Forgot Password?</h1>
        <p className={Styles["login-sub-text"]}>
          Enter your email and we’ll send you a link to reset your password
        </p>
      </div>

      <div className={Styles["auth-form-div"]}>
        <FormGroup>
          <Input
            className={`floating-input mb-4`}
            placeholder={"Email"}
            type={"text"}
            name={"email"}
            onChange={handleChange}
            value={email}
          />
          <Label className={`floating-label `}>
            Email or Username<span className="text-danger ml-1">*</span>
          </Label>
          <Icon.Mail className={Styles["input-icon"]} />
          {errors.email ? (
            <div className={"text-error"}>{errors.email}</div>
          ) : null}
        </FormGroup>
        <Row>
          <Col span={24} className="text-left ">
            <Button
              // className="return-to-login cursor-pointer"
              onClick={() => goToLogin()}
              color="link"
              className="p-0"
            >
              <Icon.ArrowLeft className={"feather-icon mr-2"} />
              Back to Login
            </Button>
          </Col>
        </Row>
        <FormGroup className="mt-4">
          <Button
            type={"submit"}
            color=""
            className={classnames(Styles["btn-submit"], "font-weight-medium")}
            block
            disabled={isLoading}
          >
            {isLoading ? (
              <CSpinner
                size="sm"
                // variant="grow"
                className="mr-2"
              />
            ) : null}
            Send Reset Link
            <Icon.ArrowRight className={Styles["feather-icon"]} />
          </Button>
        </FormGroup>
      </div>
    </Form>
  );
};
