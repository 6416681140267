import { NotificationInitialStates } from "../states";
import { NotificationActionTypes } from "../actions";

export const NotificationReducer = (
  state = NotificationInitialStates,
  action
) => {
  switch (action.type) {
    case NotificationActionTypes.NOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case NotificationActionTypes.NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: action.payload.notifications,
        totalRecords: action.payload.totalRecords,
        unReadRecords: action.payload.unReadRecords,
        error: "",
      };
    case NotificationActionTypes.NOTIFICATION_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case NotificationActionTypes.NOTIFICATION_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case NotificationActionTypes.NOTIFICATION_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
        unReadRecords: action.payload.unReadRecords,
      };
    case NotificationActionTypes.NOTIFICATION_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case NotificationActionTypes.NOTIFICATION_COUNT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case NotificationActionTypes.NOTIFICATION_COUNT_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case NotificationActionTypes.NOTIFICATION_COUNT_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
