import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getSubjects = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_SUBJECTS.service,
    ApiRoutes.GET_SUBJECTS.url,
    ApiRoutes.GET_SUBJECTS.method,
    ApiRoutes.GET_SUBJECTS.authenticate,
    payload,
    undefined
  );
  return response;
};

export const subjectService = {
  getSubjects,
};
