/** @format */

import { linkageService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const LinkageActionTypes = {
  LINKAGE_LIST_REQUEST: "LINKAGE_LIST_REQUEST",
  LINKAGE_LIST_SUCCESS: "LINKAGE_LIST_SUCCESS",
  LINKAGE_LIST_FAILED: "LINKAGE_LIST_FAILED",

  LINKAGE_GENERATE_REQUEST: "LINKAGE_GENERATE_REQUEST",
  LINKAGE_GENERATE_SUCCESS: "LINKAGE_GENERATE_SUCCESS",
  LINKAGE_GENERATE_FAILED: "LINKAGE_GENERATE_FAILED",

  LINKAGE_ATTEMPT_REQUEST: "LINKAGE_ATTEMPT_REQUEST",
  LINKAGE_ATTEMPT_SUCCESS: "LINKAGE_ATTEMPT_SUCCESS",
  LINKAGE_ATTEMPT_FAILED: "LINKAGE_ATTEMPT_FAILED",

  LINKAGE_UNLINK_REQUEST: "LINKAGE_UNLINK_REQUEST",
  LINKAGE_UNLINK_SUCCESS: "LINKAGE_UNLINK_SUCCESS",
  LINKAGE_UNLINK_FAILED: "LINKAGE_UNLINK_FAILED",

  CANCEL_REQUEST: "CANCEL_REQUEST",
  CANCEL_SUCCESS: "CANCEL_SUCCESS",
  CANCEL_FAILED: "CANCEL_FAILED",

  DISTRICT_PROGRAM_REQUEST: "DISTRICT_PROGRAM_REQUEST",
  DISTRICT_PROGRAM_SUCCESS: "DISTRICT_PROGRAM_SUCCESS",
  DISTRICT_PROGRAM_FAILED: "DISTRICT_PROGRAM_FAILED",
};

const generateRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
    } = await linkageService.generateRequest(payload);

    if (!isError) {
      dispatch(listLinkageRequest());
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: LinkageActionTypes.LINKAGE_GENERATE_REQUEST };
  }

  function success() {
    return {
      type: LinkageActionTypes.LINKAGE_GENERATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: LinkageActionTypes.LINKAGE_GENERATE_FAILED, error };
  }
};

const attemptRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
    } = await linkageService.attemptRequest(payload);

    if (!isError) {
      dispatch(listLinkageRequest());
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: LinkageActionTypes.LINKAGE_ATTEMPT_REQUEST };
  }

  function success() {
    return {
      type: LinkageActionTypes.LINKAGE_ATTEMPT_SUCCESS,
    };
  }

  function failure(error) {
    return { type: LinkageActionTypes.LINKAGE_ATTEMPT_FAILED, error };
  }
};

const unlinkLinkage = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
    } = await linkageService.unlinkLinkage(payload);

    if (!isError) {
      dispatch(listLinkageRequest());
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: LinkageActionTypes.LINKAGE_UNLINK_REQUEST };
  }

  function success() {
    return {
      type: LinkageActionTypes.LINKAGE_UNLINK_SUCCESS,
    };
  }

  function failure(error) {
    return { type: LinkageActionTypes.LINKAGE_UNLINK_FAILED, error };
  }
};

const listProgramDistrict = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await linkageService.listProgramDistrict(payload);

    if (!isError) {
      dispatch(success(responseData));
      //showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: LinkageActionTypes.DISTRICT_PROGRAM_REQUEST };
  }

  function success(responseData) {
    return {
      type: LinkageActionTypes.DISTRICT_PROGRAM_SUCCESS,
      payload: {
        districtPrograms: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: LinkageActionTypes.DISTRICT_PROGRAM_FAILED, error };
  }
};

const listLinkageRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await linkageService.listLinkageRequest(payload);

    if (!isError) {
      dispatch(success(responseData));
      //showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: LinkageActionTypes.LINKAGE_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: LinkageActionTypes.LINKAGE_LIST_SUCCESS,
      payload: {
        requests: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: LinkageActionTypes.LINKAGE_LIST_FAILED, error };
  }
};

export const linkageActions = {
  generateRequest,
  attemptRequest,
  listProgramDistrict,
  listLinkageRequest,
  unlinkLinkage,
};
