/** @format */
import { groupService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const GroupActionTypes = {
  GROUP_LIST_REQUEST: "Group Listing Request!",
  GROUP_LIST_SUCCESS: "Group Listed Successfully!",
  GROUP_LIST_FAILED: "Group Listing Failed!",
  GROUP_VIEW_REQUEST: "Group View Request!",
  GROUP_VIEW_SUCCESS: "Group View Successfully!",
  GROUP_VIEW_FAILED: "Group View Failed!",
  GROUP_STATUS_UPDATE_REQUEST: "Group Status Update Request!",
  GROUP_STATUS_UPDATE_SUCCESS: "Group Status Update Successfully!",
  GROUP_STATUS_UPDATE_FAILED: "Group Status Update Failed!",
  GROUP_ADD_REQUEST: "Group Add Request!",
  GROUP_ADD_SUCCESS: "Group Add Successfully!",
  GROUP_ADD_FAILED: "Group Add Failed!",
  GROUP_UPDATE_REQUEST: "Group Update Request!",
  GROUP_UPDATE_SUCCESS: "Group Update Successfully!",
  GROUP_UPDATE_FAILED: "Group Update Failed!",
  DELETE_GROUP_SUCCESS: "Group Deleted Successfully!",
  FILL_GROUP_DATA: "Group Data Added Successfully!",

  GROUP_MOVE_REQUEST: "GROUP_MOVE_REQUEST",
  GROUP_MOVE_SUCCESS: "GROUP_MOVE_SUCCESS",
  GROUP_MOVE_FAILED: "GROUP_MOVE_FAILED",
};

const getGroups = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await groupService.getGroups(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: GroupActionTypes.GROUP_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: GroupActionTypes.GROUP_LIST_SUCCESS,
      payload: {
        groups: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
    if (payload.search !== '') {showError("Invalid search");}
    return { type: GroupActionTypes.GROUP_LIST_FAILED, error };
  }
};

const viewGroup = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await groupService.viewGroup(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: GroupActionTypes.GROUP_VIEW_REQUEST };
  }
  function success(data) {
    return {
      type: GroupActionTypes.GROUP_VIEW_SUCCESS,
      payload: { groupData: data.data },
    };
  }
  function failure(error) {
    return { type: GroupActionTypes.GROUP_VIEW_FAILED, error };
  }
};

const updateGroupStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await groupService.updateGroupStatus(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: GroupActionTypes.GROUP_STATUS_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: GroupActionTypes.GROUP_STATUS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: GroupActionTypes.GROUP_STATUS_UPDATE_FAILED, error };
  }
};

const addGroup = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await groupService.addGroup(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: GroupActionTypes.GROUP_ADD_REQUEST };
  }

  function success() {
    return {
      type: GroupActionTypes.GROUP_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: GroupActionTypes.GROUP_ADD_FAILED, error };
  }
};

const updateGroup = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await groupService.updateGroup(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: GroupActionTypes.GROUP_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: GroupActionTypes.GROUP_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: GroupActionTypes.GROUP_UPDATE_FAILED, error };
  }
};

const deleteGroup = (payload) => {
  return async (dispatch) => {
    const { isError, messages } = await groupService.deleteGroup(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };

  function success() {
    return {
      type: GroupActionTypes.DELETE_GROUP_SUCCESS,
    };
  }
};

const fillGroupData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GroupActionTypes.FILL_GROUP_DATA, payload });
  };
};

const moveForwardGroup = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await groupService.moveForwardGroup(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: GroupActionTypes.GROUP_MOVE_REQUEST };
  }

  function success() {
    return {
      type: GroupActionTypes.GROUP_MOVE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: GroupActionTypes.GROUP_MOVE_FAILED, error };
  }
};

export const groupActions = {
  getGroups,
  viewGroup,
  updateGroupStatus,
  addGroup,
  updateGroup,
  deleteGroup,
  fillGroupData,
  moveForwardGroup,
};
