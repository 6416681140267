export const ClassInitialStates = {
  isLoading: true,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
  isSubmitting: false,
  error: false,
  classes: [],
  classData: {},
  totalRecords: 0,
};
