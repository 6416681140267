/** @format */
import { classService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const ClassActionTypes = {
  CLASS_LIST_REQUEST: "Class Listing Request!",
  CLASS_LIST_SUCCESS: "Class Listed Successfully!",
  CLASS_LIST_FAILED: "Class Listing Failed!",
  CLASS_VIEW_REQUEST: "Class View Request!",
  CLASS_VIEW_SUCCESS: "Class View Successfully!",
  CLASS_VIEW_FAILED: "Class View Failed!",
  CLASS_STATUS_UPDATE_REQUEST: "Class Status Update Request!",
  CLASS_STATUS_UPDATE_SUCCESS: "Class Status Update Successfully!",
  CLASS_STATUS_UPDATE_FAILED: "Class Status Update Failed!",
  CLASS_ADD_REQUEST: "Class Add Request!",
  CLASS_ADD_SUCCESS: "Class Add Successfully!",
  CLASS_ADD_FAILED: "Class Add Failed!",
  CLASS_UPDATE_REQUEST: "Class Update Request!",
  CLASS_UPDATE_SUCCESS: "Class Update Successfully!",
  CLASS_UPDATE_FAILED: "Class Update Failed!",
  DELETE_CLASS_SUCCESS: "Class Deleted Successfully!",
  FILL_CLASS_DATA: "Class Data Added Successfully!",

  CLASS_MOVE_REQUEST: "CLASS_MOVE_REQUEST",
  CLASS_MOVE_SUCCESS: "CLASS_MOVE_SUCCESS",
  CLASS_MOVE_FAILED: "CLASS_MOVE_FAILED",
};

const getClasses = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await classService.getClasses(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: ClassActionTypes.CLASS_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: ClassActionTypes.CLASS_LIST_SUCCESS,
      payload: {
        classes: responseData.data ? responseData.data : responseData.result,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
     if (payload.search !== '') {showError("Invalid search");}
    return { type: ClassActionTypes.CLASS_LIST_FAILED, error };
  }
};

const viewClass = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await classService.viewClass(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: ClassActionTypes.CLASS_VIEW_REQUEST };
  }
  function success(data) {
    return {
      type: ClassActionTypes.CLASS_VIEW_SUCCESS,
      payload: { classData: data.data },
    };
  }
  function failure(error) {
    return { type: ClassActionTypes.CLASS_VIEW_FAILED, error };
  }
};

const updateClassStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await classService.updateClassStatus(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: ClassActionTypes.CLASS_STATUS_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: ClassActionTypes.CLASS_STATUS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: ClassActionTypes.CLASS_STATUS_UPDATE_FAILED, error };
  }
};

const addClass = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await classService.addClass(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: ClassActionTypes.CLASS_ADD_REQUEST };
  }

  function success() {
    return {
      type: ClassActionTypes.CLASS_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: ClassActionTypes.CLASS_ADD_FAILED, error };
  }
};

const updateClass = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await classService.updateClass(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: ClassActionTypes.CLASS_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: ClassActionTypes.CLASS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: ClassActionTypes.CLASS_UPDATE_FAILED, error };
  }
};

const deleteClass = (payload) => {
  return async (dispatch) => {
    const { isError, messages } = await classService.deleteClass(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };

  function success() {
    return {
      type: ClassActionTypes.DELETE_CLASS_SUCCESS,
    };
  }
};

const fillClassData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ClassActionTypes.FILL_CLASS_DATA, payload });
  };
};

const moveForwardClass = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await classService.moveForwardClass(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: ClassActionTypes.CLASS_MOVE_REQUEST };
  }

  function success() {
    return {
      type: ClassActionTypes.CLASS_MOVE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: ClassActionTypes.CLASS_MOVE_FAILED, error };
  }
};

export const classActions = {
  getClasses,
  viewClass,
  updateClassStatus,
  addClass,
  updateClass,
  deleteClass,
  fillClassData,
  moveForwardClass,
};
