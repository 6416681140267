/** @format */

import { StudentInitialStates } from "../states";
import { StudentActionTypes } from "../actions";

export const StudentReducer = (state = StudentInitialStates, action) => {
  switch (action.type) {
    case StudentActionTypes.STUDENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
        isImportLoading: false,
      };
    case StudentActionTypes.STUDENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.payload.students,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case StudentActionTypes.STUDENT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case StudentActionTypes.ALL_STUDENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
      };
    case StudentActionTypes.ALL_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allStudents: action.payload.allStudents,
        error: "",
      };
    case StudentActionTypes.ALL_STUDENT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case StudentActionTypes.STUDENT_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isDeleted: false,
        error: "",
      };
    case StudentActionTypes.STUDENT_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentData: action.payload.studentData,
        error: "",
      };
    case StudentActionTypes.STUDENT_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case StudentActionTypes.STUDENT_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case StudentActionTypes.STUDENT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case StudentActionTypes.STUDENT_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case StudentActionTypes.STUDENT_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case StudentActionTypes.STUDENT_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case StudentActionTypes.STUDENT_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case StudentActionTypes.STUDENT_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case StudentActionTypes.STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case StudentActionTypes.STUDENT_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };

    case StudentActionTypes.STUDENT_IMPORT_REQUEST:
      return {
        ...state,
        isImportLoading: true,
        isImported: false,
        isImportSuccess: false,
        isImportMessage: "",
        error: "",
      };
    case StudentActionTypes.STUDENT_IMPORT_SUCCESS:
      return {
        ...state,
        isImportLoading: false,
        isImported: action.errorData.length ? false : true,
        error: "",
        errorData: action.errorData,
        isImportSuccess: true,
        isImportMessage: action.isImportMessage,
      };
    case StudentActionTypes.STUDENT_IMPORT_FAILED:
      return {
        ...state,
        isImportLoading: false,
        isImported: false,
        error: action.error,
      };
    case StudentActionTypes.DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case StudentActionTypes.RESET_IMPORT_ERROR:
      return {
        ...state,
        errorData: [],
      };

    case StudentActionTypes.STUDENT_MOVE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
      };
    case StudentActionTypes.STUDENT_MOVE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: "",
      };
    case StudentActionTypes.STUDENT_MOVE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    default:
      return state;
  }
};
