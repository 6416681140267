import { orderHistoryService } from "../services";
import { showError } from "../../components/MessageHandler";

export const OrderHistoryActionTypes = {
  ORDER_HISTORY_LIST_REQUEST: "ORDER_HISTORY_LIST_REQUEST",
  ORDER_HISTORY_LIST_SUCCESS: "ORDER_HISTORY_LIST_SUCCESS",
  ORDER_HISTORY_LIST_FAILED: "ORDER_HISTORY_LIST_FAILED",
};

const getOrderHistory = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await orderHistoryService.getOrderHistory(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: OrderHistoryActionTypes.ORDER_HISTORY_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: OrderHistoryActionTypes.ORDER_HISTORY_LIST_SUCCESS,
      payload: {
        orders: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
      if (payload.search !== '') {showError("Invalid search");}
    return {
      type: OrderHistoryActionTypes.ORDER_HISTORY_LIST_FAILED,
      error,
    };
  }
};

export const orderHistoryActions = {
  getOrderHistory,
};
