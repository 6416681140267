import { essayService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const EssayActionTypes = {
  ESSAY_LIST_REQUEST: "Essay Listing Request!",
  ESSAY_LIST_SUCCESS: "Essay Listed Successfully!",
  ESSAY_LIST_FAILED: "Essay Listing Failed!",

  ESSAY_VIEW_REQUEST: "Essay View Request!",
  ESSAY_VIEW_SUCCESS: "Essay View Successfully!",
  ESSAY_VIEW_FAILED: "Essay View Failed!",

  ESSAY_ADD_REQUEST: "Essay Add Request!",
  ESSAY_ADD_SUCCESS: "Essay Add Successfully!",
  ESSAY_ADD_FAILED: "Essay Add Failed!",

  ESSAY_UPDATE_REQUEST: "Essay Update Request!",
  ESSAY_UPDATE_SUCCESS: "Essay Update Successfully!",
  ESSAY_UPDATE_FAILED: "Essay Update Failed!",
};

const getEssays = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await essayService.getEssays(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: EssayActionTypes.ESSAY_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: EssayActionTypes.ESSAY_LIST_SUCCESS,
      payload: {
        essays: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
       if (payload.search !== '') {showError("Invalid search");}
    return { type: EssayActionTypes.ESSAY_LIST_FAILED, error };
  }
};

const viewEssay = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await essayService.viewEssay(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: EssayActionTypes.ESSAY_VIEW_REQUEST };
  }

  function success(data) {
    return {
      type: EssayActionTypes.ESSAY_VIEW_SUCCESS,
      payload: { essayData: data.data },
    };
  }
  function failure(error) {
    return { type: EssayActionTypes.ESSAY_VIEW_FAILED, error };
  }
};

const addEssay = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await essayService.addEssay(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: EssayActionTypes.ESSAY_ADD_REQUEST };
  }

  function success() {
    return {
      type: EssayActionTypes.ESSAY_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: EssayActionTypes.ESSAY_ADD_FAILED, error };
  }
};

const updateEssay = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await essayService.updateEssay(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: EssayActionTypes.ESSAY_UPDATE_REQUEST };
  }

  function success() {
    getEssays(payload.essayID);
    return {
      type: EssayActionTypes.ESSAY_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: EssayActionTypes.ESSAY_UPDATE_FAILED, error };
  }
};

export const essayActions = {
  getEssays,
  viewEssay,
  addEssay,
  updateEssay,
};
