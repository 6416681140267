/** @format */

import { assignmentService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const AssignmentActionTypes = {
  ASSIGNMENT_LIST_REQUEST: "ASSIGNMENT_LIST_REQUEST",
  ASSIGNMENT_LIST_SUCCESS: "ASSIGNMENT_LIST_SUCCESS",
  ASSIGNMENT_LIST_FAILED: "ASSIGNMENT_LIST_FAILED",

  ASSIGNMENT_ADD_REQUEST: "ASSIGNMENT_ADD_REQUEST",
  ASSIGNMENT_ADD_SUCCESS: "ASSIGNMENT_ADD_SUCCESS",
  ASSIGNMENT_ADD_FAILED: "ASSIGNMENT_ADD_FAILED",

  ASSIGNMENT_UPDATE_REQUEST: "ASSIGNMENT_UPDATE_REQUEST",
  ASSIGNMENT_UPDATE_SUCCESS: "ASSIGNMENT_UPDATE_SUCCESS",
  ASSIGNMENT_UPDATE_FAILED: "ASSIGNMENT_UPDATE_FAILED",

  ASSIGNMENT_DELETE_REQUEST: "ASSIGNMENT_DELETE_REQUEST",
  ASSIGNMENT_DELETE_SUCCESS: "ASSIGNMENT_DELETE_SUCCESS",
  ASSIGNMENT_DELETE_FAILED: "ASSIGNMENT_DELETE_FAILED",
};

const addAssignment = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await assignmentService.addAssignment(
      payload
    );

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: AssignmentActionTypes.ASSIGNMENT_ADD_REQUEST };
  }

  function success() {
    return {
      type: AssignmentActionTypes.ASSIGNMENT_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: AssignmentActionTypes.ASSIGNMENT_ADD_FAILED, error };
  }
};

const updateAssignment = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await assignmentService.updateAssignment(
      payload
    );
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: AssignmentActionTypes.ASSIGNMENT_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: AssignmentActionTypes.ASSIGNMENT_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: AssignmentActionTypes.ASSIGNMENT_UPDATE_FAILED, error };
  }
};

const getAssignments = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await assignmentService.getAssignments(payload);
    console.log("daaatttaaaaaa", responseData);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: AssignmentActionTypes.ASSIGNMENT_LIST_REQUEST };
  }

  function success(responseData) {
    return {   
      type: AssignmentActionTypes.ASSIGNMENT_LIST_SUCCESS,
      payload: {
        assignments: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
    if (payload.search !== '') {showError("Invalid search");}
    return { type: AssignmentActionTypes.ASSIGNMENT_LIST_FAILED, error };
  }
};
const deleteAssignment = (payload) => {
  return async (dispatch) => {
    const { isError, messages } = await assignmentService.deleteAssignment(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };
   function success() {
     return {
       type: AssignmentActionTypes.ASSIGNMENT_DELETE_SUCCESS,
     };
   }
}

export const assignmentActions = {
  addAssignment,
  getAssignments,
  updateAssignment,
  deleteAssignment,
}
