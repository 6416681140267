import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getSubadmins = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_SUBADMINS.service,
    ApiRoutes.GET_SUBADMINS.url,
    ApiRoutes.GET_SUBADMINS.method,
    ApiRoutes.GET_SUBADMINS.authenticate,
    payload,
    undefined
  );
  return response;
};

const viewSubadmin = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VIEW_SUBADMIN.service,
    ApiRoutes.VIEW_SUBADMIN.url.replace(":id", payload.id),
    ApiRoutes.VIEW_SUBADMIN.method,
    ApiRoutes.VIEW_SUBADMIN.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateSubadminStatus = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_SUBADMIN_STATUS.service,
    ApiRoutes.UPDATE_SUBADMIN_STATUS.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_SUBADMIN_STATUS.method,
    ApiRoutes.UPDATE_SUBADMIN_STATUS.authenticate,
    undefined,
    payload
  );
  return response;
};

const addSubadmin = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ADD_SUBADMIN.service,
    ApiRoutes.ADD_SUBADMIN.url,
    ApiRoutes.ADD_SUBADMIN.method,
    ApiRoutes.ADD_SUBADMIN.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateSubadmin = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_SUBADMIN.service,
    ApiRoutes.UPDATE_SUBADMIN.url.replace(":id", payload.id),
    ApiRoutes.UPDATE_SUBADMIN.method,
    ApiRoutes.UPDATE_SUBADMIN.authenticate,
    undefined,
    payload
  );
  return response;
};

const deleteSubadmin = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.DELETE_SUBADMIN.service,
    ApiRoutes.DELETE_SUBADMIN.url.replace(":id", payload.id),
    ApiRoutes.DELETE_SUBADMIN.method,
    ApiRoutes.DELETE_SUBADMIN.authenticate,
    undefined,
    undefined
  );
  return response;
};

const unitSubadmins = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UNIT_WISE_SUBADMIN.service,
    ApiRoutes.UNIT_WISE_SUBADMIN.url,
    ApiRoutes.UNIT_WISE_SUBADMIN.method,
    ApiRoutes.UNIT_WISE_SUBADMIN.authenticate,
    payload,
    undefined
  );
  return response;
};

const moveForward = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.MOVE_SUBADMIN.service,
    ApiRoutes.MOVE_SUBADMIN.url,
    ApiRoutes.MOVE_SUBADMIN.method,
    ApiRoutes.MOVE_SUBADMIN.authenticate,
    undefined,
    payload
  );
  return response;
};

export const subadminService = {
  getSubadmins,
  viewSubadmin,
  updateSubadminStatus,
  addSubadmin,
  updateSubadmin,
  deleteSubadmin,
  unitSubadmins,
  moveForward,
};
