/** @format */
import { GroupInitialStates } from "../states";
import { GroupActionTypes } from "../actions";

export const GroupReducer = (state = GroupInitialStates, action) => {
  switch (action.type) {
    case GroupActionTypes.GROUP_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
      };
    case GroupActionTypes.GROUP_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.payload.groups,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case GroupActionTypes.GROUP_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case GroupActionTypes.GROUP_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isDeleted: false,
        error: "",
      };
    case GroupActionTypes.GROUP_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupData: action.payload.groupData,
        error: "",
      };
    case GroupActionTypes.GROUP_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case GroupActionTypes.GROUP_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case GroupActionTypes.GROUP_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case GroupActionTypes.GROUP_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case GroupActionTypes.GROUP_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case GroupActionTypes.GROUP_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case GroupActionTypes.GROUP_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case GroupActionTypes.GROUP_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case GroupActionTypes.GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case GroupActionTypes.GROUP_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case GroupActionTypes.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case GroupActionTypes.FILL_GROUP_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case GroupActionTypes.GROUP_MOVE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
      };
    case GroupActionTypes.GROUP_MOVE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: "",
      };
    case GroupActionTypes.GROUP_MOVE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };

    default:
      return state;
  }
};
