/** @format */

export const ModalInitialStates = {
  teacherModalOpen: false,
  subadminModalOpen: false,
  studentModalOpen: false,
  studentsChangePasswordModalOpen: false,
  supportRequestModalOpen: false,
  supportViewModalOpen: false,
  assignmentModalOpen: false,
};
