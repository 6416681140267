import { subadminService } from "../services";

import { showError, showSuccess } from "../../components/MessageHandler";

export const SubadminActionTypes = {
  SUBADMIN_LIST_REQUEST: "Sub-Admin Listing Request!",
  SUBADMIN_LIST_SUCCESS: "Sub-Admin Listed Successfully!",
  SUBADMIN_LIST_FAILED: "Sub-Admin Listing Failed!",
  SUBADMIN_VIEW_REQUEST: "Sub-Admin View Request!",
  SUBADMIN_VIEW_SUCCESS: "Sub-Admin View Successfully!",
  SUBADMIN_VIEW_FAILED: "Sub-Admin View Failed!",
  SUBADMIN_ADD_REQUEST: "Sub-Admin Add Request!",
  SUBADMIN_ADD_SUCCESS: "Sub-Admin Add Successfully!",
  SUBADMIN_ADD_FAILED: "Sub-Admin Add Failed!",
  SUBADMIN_UPDATE_REQUEST: "Sub-Admin Update Request!",
  SUBADMIN_UPDATE_SUCCESS: "Sub-Admin Update Successfully!",
  SUBADMIN_UPDATE_FAILED: "Sub-Admin Update Failed!",
  SUBADMIN_STATUS_UPDATE_REQUEST: "Sub-Admin Status Update Request!",
  SUBADMIN_STATUS_UPDATE_SUCCESS: "Sub-Admin Status Update Successfully!",
  SUBADMIN_STATUS_UPDATE_FAILED: "Sub-Admin Status Update Failed!",
  DELETE_SUBADMIN_REQUEST: "Sub-Admin Delete Request!",
  DELETE_SUBADMIN_FAILED: "Sub-Admin Delete Failed!",
  DELETE_SUBADMIN_SUCCESS: "Sub-Admin Deleted Successfully!",

  SUBADMIN_MOVE_REQUEST: "SUBADMIN_MOVE_REQUEST",
  SUBADMIN_MOVE_SUCCESS: "SUBADMIN_MOVE_SUCCESS",
  SUBADMIN_MOVE_FAILED: "SUBADMIN_MOVE_FAILED",
};

const getSubadmins = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await subadminService.getSubadmins(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: SubadminActionTypes.SUBADMIN_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: SubadminActionTypes.SUBADMIN_LIST_SUCCESS,
      payload: {
        subadmins: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
    if (payload.search !== '') {showError("Invalid search");}
    return { type: SubadminActionTypes.SUBADMIN_LIST_FAILED, error };
  }
};

const viewSubadmin = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages, data } = await subadminService.viewSubadmin(
      payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: SubadminActionTypes.SUBADMIN_VIEW_REQUEST };
  }
  function success(data) {
    return {
      type: SubadminActionTypes.SUBADMIN_VIEW_SUCCESS,
      payload: { subadminData: data.data },
    };
  }
  function failure(error) {
    return { type: SubadminActionTypes.SUBADMIN_VIEW_FAILED, error };
  }
};

const updateSubadminStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await subadminService.updateSubadminStatus(
      payload
    );
    if (!isError) {
      /* if (payload.query) {
        dispatch(getSubadmins({ ...payload.query }));
      } else {
        dispatch(viewSubadmin({ id: payload.id }));
      } */

      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SubadminActionTypes.SUBADMIN_STATUS_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: SubadminActionTypes.SUBADMIN_STATUS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: SubadminActionTypes.SUBADMIN_STATUS_UPDATE_FAILED, error };
  }
};

const addSubadmin = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await subadminService.addSubadmin(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SubadminActionTypes.SUBADMIN_ADD_REQUEST };
  }

  function success() {
    return {
      type: SubadminActionTypes.SUBADMIN_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return { type: SubadminActionTypes.SUBADMIN_ADD_FAILED, error };
  }
};

const updateSubadmin = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await subadminService.updateSubadmin(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SubadminActionTypes.SUBADMIN_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: SubadminActionTypes.SUBADMIN_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: SubadminActionTypes.SUBADMIN_UPDATE_FAILED, error };
  }
};

const deleteSubadmin = (payload) => {
  return async (dispatch) => {
    const { isError, messages } = await subadminService.deleteSubadmin(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };

  function success() {
    return {
      type: SubadminActionTypes.DELETE_SUBADMIN_SUCCESS,
    };
  }
};

const getUnitSubadmins = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await subadminService.unitSubadmins(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: SubadminActionTypes.UNIT_SUBADMIN_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: SubadminActionTypes.UNIT_SUBADMIN_LIST_SUCCESS,
      payload: {
        unitWiseSubadmins: responseData.data,
      },
    };
  }

  function failure(error) {
    return { type: SubadminActionTypes.UNIT_SUBADMIN_LIST_FAILED, error };
  }
};

const moveForward = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await subadminService.moveForward(payload);

    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SubadminActionTypes.SUBADMIN_MOVE_REQUEST };
  }

  function success() {
    return {
      type: SubadminActionTypes.SUBADMIN_MOVE_SUCCESS,
    };
  }

  function failure(error) {
    return { type: SubadminActionTypes.SUBADMIN_MOVE_FAILED, error };
  }
};

export const subadminActions = {
  getSubadmins,
  viewSubadmin,
  updateSubadminStatus,
  addSubadmin,
  updateSubadmin,
  deleteSubadmin,
  getUnitSubadmins,
  moveForward,
};
