/** @format */

import { TeacherInitialStates } from "../states";
import { TeacherActionTypes } from "../actions";

export const TeacherReducer = (state = TeacherInitialStates, action) => {
  switch (action.type) {
    case TeacherActionTypes.TEACHER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        isImportLoading: false,
        error: "",
      };
    case TeacherActionTypes.TEACHER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teachers: action.payload.teachers,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case TeacherActionTypes.TEACHER_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case TeacherActionTypes.TEACHER_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isDeleted: false,
        error: "",
      };
    case TeacherActionTypes.TEACHER_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teacherData: action.payload.teacherData,
        error: "",
      };
    case TeacherActionTypes.TEACHER_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case TeacherActionTypes.TEACHER_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case TeacherActionTypes.TEACHER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        error: "",
      };
    case TeacherActionTypes.TEACHER_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case TeacherActionTypes.TEACHER_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case TeacherActionTypes.TEACHER_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case TeacherActionTypes.TEACHER_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case TeacherActionTypes.TEACHER_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case TeacherActionTypes.TEACHER_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case TeacherActionTypes.TEACHER_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    case TeacherActionTypes.DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case TeacherActionTypes.UNIT_TEACHER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        isDeleted: false,
        error: "",
      };
    case TeacherActionTypes.UNIT_TEACHER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unitWiseTeachers: action.payload.unitWiseTeachers,
        error: "",
      };
    case TeacherActionTypes.UNIT_TEACHER_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case TeacherActionTypes.TEACHER_IMPORT_REQUEST:
      return {
        ...state,
        isImportLoading: true,
        isImported: false,
        isImportSuccess: false,
        isImportMessage: "",
        error: "",
      };
    case TeacherActionTypes.TEACHER_IMPORT_SUCCESS:
      return {
        ...state,
        isImportLoading: false,
        isImported: action.errorData.length ? false : true,
        error: "",
        errorData: action.errorData,
        isImportSuccess: true,
        isImportMessage: action.isImportMessage,
      };
    case TeacherActionTypes.TEACHER_IMPORT_FAILED:
      return {
        ...state,
        isImportLoading: false,
        isImported: false,
        error: action.error,
      };
    case TeacherActionTypes.RESET_IMPORT_ERROR:
      return {
        ...state,
        errorData: [],
      };

    case TeacherActionTypes.TEACHER_MOVE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
      };
    case TeacherActionTypes.TEACHER_MOVE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: "",
      };
    case TeacherActionTypes.TEACHER_MOVE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    default:
      return state;
  }
};
