/** @format */

import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import logger from "redux-logger";
import { RootReducer } from "../reducers";

export default function configureStore(history, initialState) {
  const middlewares = [thunkMiddleware, routerMiddleware(history)];

  // Add logger middleware for development mode
  const isProd = process.env.NODE_ENV === "production";
  if (!isProd) {
    middlewares.push(logger);
  }
  const store = createStore(
    RootReducer,
    initialState,
    applyMiddleware(...middlewares)
  );

  return store;
}
