import { notificationService } from "../services";
import { showError } from "../../components/MessageHandler";

export const NotificationActionTypes = {
  NOTIFICATION_LIST_REQUEST: "Notification Listing Request!",
  NOTIFICATION_LIST_SUCCESS: "Notification Listed Successfully!",
  NOTIFICATION_LIST_FAILED: "Notification Listing Failed!",

  NOTIFICATION_STATUS_UPDATE_REQUEST: "Notification Status Update Request!",
  NOTIFICATION_STATUS_UPDATE_SUCCESS:
    "Notification Status Update Successfully!",
  NOTIFICATION_STATUS_UPDATE_FAILED: "Notification Status Update Failed!",

  NOTIFICATION_COUNT_UPDATE_REQUEST: "Notification Count Update Request!",
  NOTIFICATION_COUNT_UPDATE_SUCCESS: "Notification Count Update Successfully!",
  NOTIFICATION_COUNT_UPDATE_FAILED: "Notification Count Update Failed!",
};

const getNotifications = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await notificationService.getNotifications(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: NotificationActionTypes.NOTIFICATION_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: NotificationActionTypes.NOTIFICATION_LIST_SUCCESS,
      payload: {
        notifications: responseData.data,
        totalRecords: responseData.totalRecords,
        unReadRecords: responseData.unReadRecords,
      },
    };
  }

  function failure(error) {
    return { type: NotificationActionTypes.NOTIFICATION_LIST_FAILED, error };
  }
};

const updateNotificationStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await notificationService.updateNotificationStatus(payload);
    if (!isError) {
      dispatch(success(responseData));
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: NotificationActionTypes.NOTIFICATION_STATUS_UPDATE_REQUEST };
  }

  function success(responseData) {
    return {
      type: NotificationActionTypes.NOTIFICATION_STATUS_UPDATE_SUCCESS,
      payload: {
        unReadRecords: responseData.unReadRecords,
      },
    };
  }

  function failure(error) {
    return {
      type: NotificationActionTypes.NOTIFICATION_STATUS_UPDATE_FAILED,
      error,
    };
  }
};

const updateNotificationCount = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } =
      await notificationService.updateNotificationCount(payload);
    if (!isError) {
      dispatch(success());
      // showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: NotificationActionTypes.NOTIFICATION_COUNT_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: NotificationActionTypes.NOTIFICATION_COUNT_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: NotificationActionTypes.NOTIFICATION_COUNT_UPDATE_FAILED,
      error,
    };
  }
};

export const notificationActions = {
  getNotifications,
  updateNotificationStatus,
  updateNotificationCount,
};
