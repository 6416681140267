/** @format */
export const ReportInitialStates = {
  isLoading: true,
  isActDetailLoading: true,
  isCourseOverviewLoading: true,
  isActScoreOverviewLoading: true,
  isQuizScoreOverviewLoading: true,
  isTestCompletionLoading: true,
  isScoreComparisonLoading: true,
  isActPerformanceLoading: true,
  isQuizProgressLoading: true,
  isQuizProgressTestScoreLoading: true,
  error: false,
  studentTestReportList: [],
  studentReportList: [],
  actDetailReportData: {},
  studentCourseReportData: [],
  studentQuizScoreReportData: [],
  studentActScoreReportData: [],
  totalRecords: 0,
  totalStudent: 0,
  studentList: [],
  assignStudentList: [],
  classList: [],
  studentTestScore: {},
};
