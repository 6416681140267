import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getOrderHistory = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_ORDER_HISTORY.service,
    ApiRoutes.GET_ORDER_HISTORY.url,
    ApiRoutes.GET_ORDER_HISTORY.method,
    ApiRoutes.GET_ORDER_HISTORY.authenticate,
    payload,
    undefined
  );
  return response;
};

export const orderHistoryService = {
  getOrderHistory,
};
