export const EssayInitialStates = {
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  isSubmitting: false,
  error: false,
  essays: [],
  essayData: {},
  totalRecords: 0,
};
