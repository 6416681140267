import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const generateRequest = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GENERATE_LINKAGE.service,
    ApiRoutes.GENERATE_LINKAGE.url,
    ApiRoutes.GENERATE_LINKAGE.method,
    ApiRoutes.GENERATE_LINKAGE.authenticate,
    undefined,
    payload
  );
  return response;
};

const attemptRequest = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.ATTEMPT_REQUEST.service,
    ApiRoutes.ATTEMPT_REQUEST.url.replace(":id", payload.id),
    ApiRoutes.ATTEMPT_REQUEST.method,
    ApiRoutes.ATTEMPT_REQUEST.authenticate,
    undefined,
    payload
  );
  return response;
};

const unlinkLinkage = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UNLINK_LINKAGE.service,
    ApiRoutes.UNLINK_LINKAGE.url.replace(":id", payload.id),
    ApiRoutes.UNLINK_LINKAGE.method,
    ApiRoutes.UNLINK_LINKAGE.authenticate,
    undefined,
    payload
  );
  return response;
};

const listProgramDistrict = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.LIST_PROGRAM_DISTRICT.service,
    ApiRoutes.LIST_PROGRAM_DISTRICT.url,
    ApiRoutes.LIST_PROGRAM_DISTRICT.method,
    ApiRoutes.LIST_PROGRAM_DISTRICT.authenticate,
    undefined,
    payload
  );
  return response;
};

const listLinkageRequest = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.LIST_LINKAGE_REQUEST.service,
    ApiRoutes.LIST_LINKAGE_REQUEST.url,
    ApiRoutes.LIST_LINKAGE_REQUEST.method,
    ApiRoutes.LIST_LINKAGE_REQUEST.authenticate,
    undefined,
    undefined
  );
  return response;
};

export const linkageService = {
  generateRequest,
  attemptRequest,
  listProgramDistrict,
  listLinkageRequest,
  unlinkLinkage,
};
