export const AppRoutes = {
  HOME: "/",
  PAGE_NOT_FOUND: "/404",
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  MY_PROFILE: "/profile",
  SETTINGS: "/setting",
  TEACHER: "/teachers",
  VIEW_TEACHER: "/teachers/view/:id",
  STUDENT: "/students",
  VIEW_STUDENT: "/students/view/:id",
  WRITING: "/writing",
  ADD_WRITING: "/writing/add",
  VIEW_WRITING: "/writing/view",
  GROUP_MANAGEMENT: "/group-management",
  VIEW_GROUP_MANAGEMENT: "/group-management/view",
  ADD_GROUP_MANAGEMENT: "/group-management/add",
  ORDER_HISTORY: "/order-history",
  SUB_ADMIN: "/sub-admin",
  VIEW_SUB_ADMIN: "/sub-admin/view/:id",
  CLASSROOMS: "/classrooms",
  ADD_CLASSROOMS: "/classrooms/add",
  EDIT_CLASSROOMS: "/classrooms/edit/:id",
  VIEW_CLASSROOMS: "/classrooms/view/:id",
  // VIEW_EMPLOYEE: "/employee/view",
  CLASS: "/classes",
  ADD_CLASS: "/classes/add",
  EDIT_CLASS: "/classes/edit/:id",
  VIEW_CLASS: "/classes/view/:id",
  GROUP: "/group",
  VIEW_GROUP: "/group/view/:id",
  ADD_GROUP: "/group/add",
  SUPPORT_REQUEST: "/support-request",
  EDIT_GROUP: "/group/edit/:id",
  SEAT: "/seat-management",
  SUPPORT: "/support",
  ESSAY: "/essay",
  EDIT_ESSAY: "/essay/edit/:id",
  ASSIGNMENT: "/assignments",
  VIEW_ASSIGNMENT: "/assignments/view/:id",
  REPORT: "/report",
  VIEW_ACT_DETAILED_REPORT: "/report/act-detailed-report",
  VIEW_ACT_PERFORMANCE_REPORT: "/report/act-performance-report",
  VIEW_ACT_SCORE_COMPARISON_REPORT: "/report/act-score-comparison-report",
  VIEW_STUDENT_PROGRESS_REPORT: "/report/student-progress-report",
  VIEW_QUIZ_PROGRESS_REPORT: "/report/quiz-progress-report",
  ProxyLogin: "/proxy-login"
};
