import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const login = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.LOGIN.service,
    ApiRoutes.LOGIN.url,
    ApiRoutes.LOGIN.method,
    ApiRoutes.LOGIN.authenticate,
    undefined,
    payload
  );
  return response;
};

const getUserDetails = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_PROFILE_DETAILS.service,
    ApiRoutes.GET_PROFILE_DETAILS.url,
    ApiRoutes.GET_PROFILE_DETAILS.method,
    ApiRoutes.GET_PROFILE_DETAILS.authenticate,
    undefined,
    undefined
  );
  return response;
};

const basicDetailByDomain = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.BASIC_DETAIL_BY_DOMAIN.service,
    ApiRoutes.BASIC_DETAIL_BY_DOMAIN.url,
    ApiRoutes.BASIC_DETAIL_BY_DOMAIN.method,
    ApiRoutes.BASIC_DETAIL_BY_DOMAIN.authenticate,
    payload,
    undefined
  );
  return response;
};

const setPassword = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.SET_PASSWORD.service,
    ApiRoutes.SET_PASSWORD.url.replace(":id", payload.id),
    ApiRoutes.SET_PASSWORD.method,
    ApiRoutes.SET_PASSWORD.authenticate,
    undefined,
    payload
  );
  return response;
};

const updateProfile = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.UPDATE_PROFILE.service,
    ApiRoutes.UPDATE_PROFILE.url.replace(":id", payload._id),
    ApiRoutes.UPDATE_PROFILE.method,
    ApiRoutes.UPDATE_PROFILE.authenticate,
    undefined,
    payload
  );
  return response;
};

const changePassword = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.CHANGE_PASSWORD.service,
    ApiRoutes.CHANGE_PASSWORD.url,
    ApiRoutes.CHANGE_PASSWORD.method,
    ApiRoutes.CHANGE_PASSWORD.authenticate,
    undefined,
    payload
  );
  return response;
};

const verifyToken = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.VERIFY_TOKEN.service,
    ApiRoutes.VERIFY_TOKEN.url,
    ApiRoutes.VERIFY_TOKEN.method,
    ApiRoutes.VERIFY_TOKEN.authenticate,
    undefined,
    payload
  );
  return response;
};

const forgotPassword = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.FORGOT_PASSWORD.service,
    ApiRoutes.FORGOT_PASSWORD.url,
    ApiRoutes.FORGOT_PASSWORD.method,
    ApiRoutes.FORGOT_PASSWORD.authenticate,
    payload,
    undefined
  );
  return response;
};

export const authService = {
  login,
  basicDetailByDomain,
  setPassword,
  getUserDetails,
  updateProfile,
  changePassword,
  verifyToken,

  forgotPassword,
};
