import { ApiHelper } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";

const getAllStudentList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_LIST.service,
    ApiRoutes.GET_STUDENT_LIST.url,
    ApiRoutes.GET_STUDENT_LIST.method,
    ApiRoutes.GET_STUDENT_LIST.authenticate,
    payload,
    undefined
  );
  return response;
};

const getAllClassList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_CLASS_LIST.service,
    ApiRoutes.GET_CLASS_LIST.url,
    ApiRoutes.GET_CLASS_LIST.method,
    ApiRoutes.GET_CLASS_LIST.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentTestReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_TEST_REPORT.service,
    ApiRoutes.GET_STUDENT_TEST_REPORT.url,
    ApiRoutes.GET_STUDENT_TEST_REPORT.method,
    ApiRoutes.GET_STUDENT_TEST_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentScoreComparisonReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_COMPARISON_REPORT.service,
    ApiRoutes.GET_STUDENT_COMPARISON_REPORT.url,
    ApiRoutes.GET_STUDENT_COMPARISON_REPORT.method,
    ApiRoutes.GET_STUDENT_COMPARISON_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentActPerformanceReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.service,
    ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.url,
    ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.method,
    ApiRoutes.GET_STUDENT_ACT_PERFORMANCE_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentQuizProgressReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.service,
    ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.url,
    ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.method,
    ApiRoutes.GET_STUDENT_QUIZ_PROGRESS_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentActDetailedReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.service,
    ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.url,
    ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.method,
    ApiRoutes.GET_STUDENT_ACT_DETAILED_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentCourseProgressOverviewReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.service,
    ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.url,
    ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.method,
    ApiRoutes.GET_STUDENT_COURSE_PROGRESS_OVERVIEW_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentActScoreProgressOverviewReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.service,
    ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.url,
    ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.method,
    ApiRoutes.GET_STUDENT_ACT_SCORE_PROGRESS_OVERVIEW_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

const getStudentQuizScoreProgressOverviewReportList = async (payload) => {
  const response = await new ApiHelper().FetchFromServer(
    ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.service,
    ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.url,
    ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.method,
    ApiRoutes.GET_STUDENT_QUIZ_SCORE_PROGRESS_OVERVIEW_REPORT.authenticate,
    payload,
    undefined
  );
  return response;
};

export const reportRequestService = {
  getStudentTestReportList,
  getStudentScoreComparisonReportList,
  getAllStudentList,
  getAllClassList,
  getStudentActPerformanceReportList,
  getStudentQuizProgressReportList,
  getStudentActDetailedReportList,
  getStudentCourseProgressOverviewReportList,
  getStudentQuizScoreProgressOverviewReportList,
  getStudentActScoreProgressOverviewReportList,
};
