import { supportRequestService } from "../services";
import { showError, showSuccess } from "../../components/MessageHandler";

export const SupportRequestActionTypes = {
  SUPPORT_REQUEST_ADD_REQUEST: "Support Request Add Request!",
  SUPPORT_REQUEST_ADD_SUCCESS: "Support Request Add Successfully!",
  SUPPORT_REQUEST_ADD_FAILED: "Support Request Add Failed!",
  SUPPORT_REQUEST_LIST_REQUEST: "Support Request Listing Request!",
  SUPPORT_REQUEST_LIST_SUCCESS: "Support Request Listed Successfully!",
  SUPPORT_REQUEST_LIST_FAILED: "Support Request Listing Failed!",
  SUPPORT_REQUEST_VIEW_REQUEST: "Support Request View Request!",
  SUPPORT_REQUEST_VIEW_SUCCESS: "Support Request View Successfully!",
  SUPPORT_REQUEST_VIEW_FAILED: "Support Request View Failed!",
  SUPPORT_REQUEST_STATUS_UPDATE_REQUEST:
    "Support Request Status Update Request!",
  SUPPORT_REQUEST_STATUS_UPDATE_SUCCESS:
    "Support Request Status Update Successfully!",
  SUPPORT_REQUEST_STATUS_UPDATE_FAILED: "Support Request Status Update Failed!",
  SUPPORT_REQUEST_UPDATE_REQUEST: "Support Request Update Request!",
  SUPPORT_REQUEST_UPDATE_SUCCESS: "Support Request Update Successfully!",
  SUPPORT_REQUEST_UPDATE_FAILED: "Support Request Update Failed!",
};

const getSupportRequests = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data: responseData,
    } = await supportRequestService.getSupportRequests(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(responseData));
    }
  };

  function request() {
    return { type: SupportRequestActionTypes.SUPPORT_REQUEST_LIST_REQUEST };
  }

  function success(responseData) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_LIST_SUCCESS,
      payload: {
        supportRequests: responseData.data,
        totalRecords: responseData.totalRecords,
      },
    };
  }

  function failure(error) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_LIST_FAILED,
      error,
    };
  }
};

const viewSupportRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
      data,
    } = await supportRequestService.viewSupportRequest(payload);
    if (isError) {
      showError(messages[0]);
      dispatch(failure(isError));
    } else {
      dispatch(success(data));
    }
  };

  function request() {
    return { type: SupportRequestActionTypes.SUPPORT_REQUEST_VIEW_REQUEST };
  }
  function success(data) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_VIEW_SUCCESS,
      payload: { teacherData: data.data },
    };
  }
  function failure(error) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_VIEW_FAILED,
      error,
    };
  }
};

const updateSupportRequestStatus = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
    } = await supportRequestService.updateSupportRequestStatus(payload);
    if (!isError) {
      /* if (payload.query) {
        dispatch(getSupport Requests({ ...payload.query }));
      } else {
        dispatch(viewSupport Request({ id: payload.id }));
      } */

      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_STATUS_UPDATE_REQUEST,
    };
  }

  function success() {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_STATUS_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_STATUS_UPDATE_FAILED,
      error,
    };
  }
};

const addSupportRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const { isError, messages } = await supportRequestService.addSupportRequest(
      payload
    );
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SupportRequestActionTypes.SUPPORT_REQUEST_ADD_REQUEST };
  }

  function success() {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_ADD_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_ADD_FAILED,
      error,
    };
  }
};

const updateSupportRequest = (payload) => {
  return async (dispatch) => {
    dispatch(request());
    const {
      isError,
      messages,
    } = await supportRequestService.updateSupportRequest(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      dispatch(failure(isError));
      showError(messages[0]);
    }
  };

  function request() {
    return { type: SupportRequestActionTypes.SUPPORT_REQUEST_UPDATE_REQUEST };
  }

  function success() {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_UPDATE_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: SupportRequestActionTypes.SUPPORT_REQUEST_UPDATE_FAILED,
      error,
    };
  }
};

const deleteSupportRequest = (payload) => {
  return async (dispatch) => {
    const {
      isError,
      messages,
    } = await supportRequestService.deleteSupportRequest(payload);
    if (!isError) {
      dispatch(success());
      showSuccess(messages[0]);
    } else {
      showError(messages[0]);
    }
  };

  function success() {
    return {
      type: SupportRequestActionTypes.DELETE_SUPPORT_REQUEST_SUCCESS,
    };
  }
};

export const supportRequestActions = {
  getSupportRequests,
  viewSupportRequest,
  updateSupportRequestStatus,
  addSupportRequest,
  updateSupportRequest,
  deleteSupportRequest,
};
