import { EssayInitialStates } from "../states";
import { EssayActionTypes } from "../actions";

export const EssayReducer = (state = EssayInitialStates, action) => {
  switch (action.type) {
    case EssayActionTypes.ESSAY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        isAdded: false,
        error: "",
      };
    case EssayActionTypes.ESSAY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        essays: action.payload.essays,
        totalRecords: action.payload.totalRecords,
        error: "",
      };
    case EssayActionTypes.ESSAY_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case EssayActionTypes.ESSAY_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: "",
      };
    case EssayActionTypes.ESSAY_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        essayData: action.payload.essayData[0],
        error: "",
      };
    case EssayActionTypes.ESSAY_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case EssayActionTypes.ESSAY_ADD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isAdded: false,
        error: "",
      };
    case EssayActionTypes.ESSAY_ADD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isAdded: true,
        error: "",
      };
    case EssayActionTypes.ESSAY_ADD_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };

    case EssayActionTypes.ESSAY_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: "",
      };
    case EssayActionTypes.ESSAY_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        error: "",
      };
    case EssayActionTypes.ESSAY_UPDATE_FAILED:
      return {
        ...state,
        isUpdated: false,
        isSubmitting: false,
        error: action.error,
      };

    default:
      return state;
  }
};
